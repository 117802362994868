import React from 'react';
import DataService, { InventoryItem } from "./DataService";
import QRCode from "qrcode";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Button from "@mui/material/Button";
import { Box, FormControl, InputLabel, Select, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import SerialScanner from './SerialScanner';
import InventoryFormActions, { InventoryFormActionProps } from './InventoryFormActions';
import { Html5Qrcode } from "html5-qrcode";
import { Html5QrcodeResult } from "html5-qrcode/core";
import { Html5QrcodeCameraScanConfig } from "html5-qrcode/html5-qrcode";
import './InventoryItemForm.css';


type Camera = { id: string, label: string }

export enum InventoryItemFormType {
    Update,
    AddNew,
}

export interface InventoryItemFormProps extends InventoryFormActionProps {
    dataService?: DataService,
    item: Partial<InventoryItem>,
    displayErrors?: boolean,
    formType?: InventoryItemFormType,
    onChangeCallback?: (item: Partial<InventoryItem>) => void,
    onValidate?: (errors: {valid: boolean, messages: []}) => void,
    onDelete?: () => void,
    onFormEdit?: () => void,
    onResetVisited?: (asset: InventoryItem | undefined) => void,
}

function InventoryItemForm({dataService, item, displayErrors, formType, onChangeCallback, onValidate, onViewMap, onDelete, onFormEdit, onResetVisited}: InventoryItemFormProps) {
    const previewRef = React.createRef<HTMLCanvasElement>();

    const [assetTypes, setAssetTypes] = React.useState<string[]>([]);
    const [crossFunctionalTeams, setCrossFunctionalTeams] = React.useState<string[]>([]);
    const [plants, setPlants] = React.useState<string[]>([]);
    
    const [satelliteTags, setSatelliteTags] = React.useState<string[]>([]);
    const [lteTags, setLteTags] = React.useState<string[]>([]);
    const [bluetoothTags, setBluetoothTags] = React.useState<string[]>([]);

    const [isFormEditable, setFormEditable] = React.useState(formType == InventoryItemFormType.Update || formType == InventoryItemFormType.AddNew);
    const [formState, setFormState] = React.useState<Partial<InventoryItem>>(item);
    const html5QrCodeRef = React.useRef<Html5Qrcode | null>(null);
    const [camera, setCamera] = React.useState<Camera | null>(null);
    const [errors, setErrors] = React.useState<any>({
        name: { valid: false, initialEntry: false, message: 'Non-Unique/Missing name'},
        assetType: { valid: false, initialEntry: false, message: 'Missing asset type'},
        crossFunctionalTeam: { valid: false, initialEntry: false, message: 'Missing cross functional team' },
        plant: { valid: false, initialEntry: false, message: 'Missing plant' },
        location: { valid: false, initialEntry: false, message: 'Missing location/Please enable browser location settings.' },
        tagType: { valid: false, initialEntry: false, message: 'Missing tag type' },
        tagId: { valid: false, initialEntry: false, message: 'Missing tag id' }
    })
    const [tagAssociated, setTagAssociated] = React.useState<boolean>(!!formState?.tagId)

    const [valid, setValid] = React.useState({
        valid: true,
        message: ''
    })

    const updateItem = (e: any) => {
        if (isFormEditable) {
            setFormState({ ...formState, [e.target.name]: e.target.value as any })
            onChangeCallback?.({ ...formState, [e.target.name]: e.target.value as any })
            setErrors({...errors, [e.target.name]: {...errors[e.target.name], valid: validateText(e.target.value), initialEntry: true}})

            if(e.target.name == "name"){
                setErrors({...errors, [e.target.name]: {...errors[e.target.name], valid: isAssetNameUnique(e.target.value), initialEntry: true}})
            }            
        }
    };

    const saveUpdate = () => {
        if(valid.valid){
                if(formState.tagId === undefined){
                    formState.tagId = "";
                }
                dataService?.updateInventoryItem(formState as InventoryItem);
                onFormEdit?.()          
                let backendTagType = dataService?.getTagTypeName(formState as InventoryItem);
                if(backendTagType){
                    formState.tagTypeName = backendTagType;
                    item.tagTypeName = backendTagType;
                }      
                if(!!formState.tagId){
                    setTagAssociated(true);
                }
                else{
                    setTagAssociated(false);                    
                    formState.tagType = undefined;
                }
            }
            else{
                console.log(`${valid.message}`);
                setFormEditable(true);
            }
    };

    const updateVisit = () => {
        setFormState({...formState, visitedTimestamp: new Date().toISOString()})
    }

    const generateBarcode = async () => {
        const tagId = randomBarcode(12);
        setFormState({...formState, tagId})
        onChangeCallback?.({...item, tagId});
        setErrors({...errors, tagId:{...errors.tagId, valid: validateText(tagId), initialEntry: true}})
    };

    const printBarcode = () => {
        var items = new Array<InventoryItem>();
        items.push(formState as InventoryItem);

        printInventoryLabels(items);
    }

    const deleteItem = () => {
        var id = (formState as InventoryItem).id;
        var tagType = (formState as InventoryItem).tagType;

        dataService?.removeInventoryItem(id, tagType);
        onDelete?.()
    }

    const onRemoveTag = async () => {
        let isRemoved = await dataService?.removeTag(formState);
        console.log(`isRemoved=${isRemoved}`)
        if(isRemoved){
            item.tagId = undefined;
            item.tagType = undefined;
            item.tagTypeName = undefined;
            formState.tagTypeName = undefined;
            formState.tagId = undefined;
            formState.tagType = undefined;
            setTagAssociated(false);
        }
    }

    async function printInventoryLabels(items: InventoryItem[]) {
        const iframe = (
            document.querySelector('#printInventory') || document.createElement('iframe')
        ) as HTMLIFrameElement;
        iframe.id = 'printInventory';
        iframe.style.display = 'none';
        iframe.onload = () => {
            if (iframe.contentWindow) {
                iframe.contentWindow.print();
            } else {
                console.error('Content window is null');
            }
        };
    
        const barcodes = await Promise.all(items.map(i => QRCode.toDataURL(i.tagId, {errorCorrectionLevel: 'H'})));
    
        iframe.srcdoc = `<!doctype html>
    <html lang="en" style="height: 100%;">
    <head>
    <title>Inventory Labels</title>
    </head>
    <body style="height: 100%; margin: 0; padding: 0; box-sizing: border-box; font-family: sans-serif">
    ${items.map((item, i) => `<div style="width: 50%; display: inline-block; text-align: center; border: 1px dashed #555; box-sizing: border-box; padding-bottom: 1rem">
    <img src="${barcodes[i]}" style="height: 320px; max-width: 100%" alt="${item.tagId}"><br/>
    <pre style="display: block; margin-top: -2rem; font-size: 26px">${item.tagId}</pre>
    ${item.latitude && item.longitude ? `<span style="font-weight: bold; font-size: 21px; color: #555555;">${item.latitude}, ${item.longitude}</span>` : '<br/>'}
    <p style="padding-top: 1rem;">${item.name}</p>
    </div>`).join('')}
    </body>
    </html>`;
        if (!iframe.parentElement) {
            document.body.appendChild(iframe);
        }
    }

    const processOCR = (ocr: any): string => {

        for (let i = 0; i < ocr.length; ++i) {
            for (let j = 0; j < ocr[i].lines.length; ++j) {
                let text = ocr[i].lines[j].text.replace(/\s/g, '')
                if (text.match(/^[\w\-_\/]{4,30}$/g)) {
                    return text
                }
            }
        }

        return ''
    }

    const validateText = (value: string | undefined | null): boolean => {
        return !!value;
    }

    const isAssetNameUnique = (value: string | undefined | null): boolean => {
        let unique = true;
        if(value && value.length > 0){
            let items = dataService?.listInventoryItems();
            if(items){
                for(let i=0; i< items.length; i++){                    
                    if(items[i] && items[i].name.trim().toLowerCase() === value.trim().toLowerCase()){
                        unique = false;                        
                        break;
                    }
                }
            }
        }

        setValid({valid: unique, message: 'Non-Unique Asset name.'})
        
        return unique;
    }

    const checkErrors = (): any => {
        let errorMessages = [] as string[]

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                if(!errors[key].valid) {
                    errorMessages.push(errors[key].message)
                }
            }
        }

        return {valid: errorMessages.length === 0, messages: errorMessages}
    }

    const formatTimestamp = (timestamp: string | undefined): string | undefined => {
        if (timestamp) {
            let b = timestamp.split(/\D/);
            let split = b[0] + '-' + b[1] + '-' + b[2] + 'T' +
                b[3] + ':' + b[4] + ':' + b[5] + '.' +
                b[6].substring(0,3) + '+00:00';
            return (new Date(split))
                .toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'})
                .replace(/(\d+)\/(\d+)\/(\d+), (\d+)\:(\d+)\:(\d+)/, '$3-$1-$2 $4:$5:$6');
        }
        return undefined;
    }

    const isVisitable = (item: Partial<InventoryItem>): boolean | undefined => {
        return item.assetType === 'Vent/Drain'
    }

    const isHEIAsset = (item: Partial<InventoryItem>): boolean | undefined => {
        return item.assetType === 'HEI'
    }

    const tagIsBarcode = (item: Partial<InventoryItem>): boolean | undefined => {
        return item.tagType?.includes('Barcode') || item.tagTypeName === 'Barcode'
    }

    const onVisitedReset = () =>{
        setFormState({...formState, visitedTimestamp: undefined});
        onResetVisited?.(formState as InventoryItem);
        onFormEdit?.();
    }

    React.useEffect(() => {
        console.log('getting current position');
        navigator.geolocation.getCurrentPosition(success, error);
    }, []);

    function success(e: any) {
        setFormState({...formState, latitude: e.coords.latitude, longitude: e.coords.longitude})
        onChangeCallback?.({...item, latitude: e.coords.latitude, longitude: e.coords.longitude})
        setErrors({...errors, location: {...errors.location, valid: (e.coords.latitude != null && e.coords.longitude != null), initialEntry: true}})    
    }
    
    function error(err: any) {
        console.log(`Location Failure. ERROR(${err.code}): ${err.message}`);
        alert(`Please enable browser location settings.`)
    }

    React.useEffect(() => {
        console.log('updating item');
                setFormState({ ...formState, 
            assetType: item.assetType,
            tagType: item.tagType, 
            tagId: item.tagId
        });

        onChangeCallback?.({ ...formState, 
            assetType: item.assetType, 
            tagType:  item.tagType, 
            tagId: item.tagId
        });

        setErrors({...errors, 
            assetType: {...errors.assetType, valid: validateText(item.assetType)},
            tagId: {...errors.tagId, valid: validateText(item.tagId)},
            tagType: {...errors.tagType, valid: validateText(item.tagType)}
        });        

        if(!!item.assetType){
            if(item.assetType === "HEI"){
                setErrors({...errors, tagId: { ...errors.tagId,valid: true}, tagType: {...errors.tagType, valid: true}});
            }
            else{
                if(item.tagId === ""){
                    setErrors({...errors,tagId: { ...errors.tagId,valid: false}, tagType: {...errors.tagType, valid: false}})
                }
            }
        }
    }, [item.assetType, item.tagType, item.tagId]);

    React.useEffect(() => {
        if(formState && formState.assetType === 'Vent/Drain') {
            dataService?.updateVisited(formState as InventoryItem)
        }
    }, [
        formState.visitedTimestamp
    ])

    React.useEffect(() => {
        onValidate?.(checkErrors())
    }, [errors, valid.valid])

    React.useEffect(() => {
            if (previewRef.current && onChangeCallback) {
                if (item.tagId) {
                    QRCode.toCanvas(previewRef.current, item.tagId, {errorCorrectionLevel: 'H'}, (error) => {
                        if (error) {
                            console.error(error)
                        }
                    });
                } else {
                    previewRef.current.style.width = '0';
                    previewRef.current.style.height = '0';
                }
            }
    }, [previewRef, item.tagId, onChangeCallback]);

    React.useEffect(() => {
        (async () => {
            let assetTypes = await dataService?.loadAssetTypes();
            if (assetTypes) {
                setAssetTypes(assetTypes);
            }

            let crossFunctionalTeams = await dataService?.loadCrossFunctionalTeams();
            
            if (crossFunctionalTeams) {
                setCrossFunctionalTeams(crossFunctionalTeams);
            }

            let plants = await dataService?.loadPlants();
            if (plants) {
                setPlants(plants);
            }
        })();
    }, [isFormEditable]);

    /**
     * Satellites and LTE need to be frequently reloaded to minimize conflicts
     * between the set of available tags
     */
    React.useEffect(() => {
        if(formState.tagType === 'Satellite' || formState.tagTypeName === 'Satellite') {
            (async () => {
                let satelliteTags = await dataService?.loadUnassignedSatellites();
                if (satelliteTags) {
                    setSatelliteTags(satelliteTags)
                }
            })()
        } else if (formState.tagType === 'LTE' || formState.tagTypeName === 'LTE') {
            (async () => {
                let lteTags = await dataService?.loadUnassignedLteTags();
                if (lteTags) {
                    setLteTags(lteTags);
                }
            })();
        } else if (formState.tagType === 'Bluetooth' || formState.tagTypeName === 'Bluetooth') {
            (async () => {
                let bluetoothTags = await dataService?.loadUnassignedBluetoothTags();
                if (bluetoothTags) {
                    setBluetoothTags(bluetoothTags);
                }
            })();
        }
    }, [formState.tagType, formState.tagTypeName])

    React.useEffect(() => {
        if(!formState.visitedTimestamp && formState.assetType === 'Vent/Drain') {
            setFormState({...formState, visitedTimestamp: new Date().toISOString()})
        } else if (formState.assetType !== 'Vent/Drain') {
            setFormState({...formState, visitedTimestamp: undefined})
        }
    }, [formState.assetType])

    React.useEffect(() => {
        if (html5QrCodeRef.current && camera) {
            // setStatus(`Scanning with ${camera.label}`);
            console.log(`Scanning with ${camera.label}`);
            html5QrCodeRef.current.start(camera.id, {
                    // fps: 10,    // Optional, frame per seconds for qr code scanning
                    qrbox: {width: 240, height: 240}  // Optional, if you want bounded box UI
                } as Html5QrcodeCameraScanConfig,
                (decodedText: string, decodedResult: Html5QrcodeResult) => {
                    console.log("Decoded Text", decodedText, decodedResult);
                    html5QrCodeRef.current?.pause(true);
                    setFormState({...formState, tagId: decodedText});
                    onChangeCallback?.({ ...item, tagId: decodedText })
                    setErrors({...errors, tagId:{...errors.tagId, valid: validateText(decodedText), initialEntry: true}})
                },
                () => {
                    //
                }).catch((e: Error) => {
                    console.error("Error while starting Scanner", e);
                });
        }
    }, [camera]);

    React.useEffect(() => {
        // This method will trigger user permissions
        if (formState.tagType === 'ExistingBarcode') {
            Html5Qrcode.getCameras().then(devices => {
                // setCameras(devices);
                if (devices.length > 0) {
                    for (var i = 0; i < devices.length; i++) {
                        console.log(`camera ${i}: ${devices[i].label}`);
                    }
                    setCamera(devices[devices.length - 1]);
                }
            });
            const html5QrCode = new Html5Qrcode("scanner");
            html5QrCodeRef.current = html5QrCode;
            return () => {
                console.log("stopping scanner");
                if (html5QrCode.isScanning && html5QrCode.getState()) {
                    html5QrCode.stop().then(() => {
                        html5QrCode.clear();
                    });
                } else {
                    html5QrCode.clear();
                }
            };
        }
    }, [formState.tagType])

    var assetType = isFormEditable && ((formType == InventoryItemFormType.Update && !!formState && !!formState.assetType && formState.assetType !== "HEI") || formType == InventoryItemFormType.AddNew)?
        <Grid item sx={{paddingTop: 2}} className='editableField'>
            <FormControl fullWidth variant="standard" error={(displayErrors || errors.assetType.initialEntry) && !errors.assetType.valid} required>
                <InputLabel id="asset-type-label" sx={{pl: 2}}>Asset Type</InputLabel>
                <Select
                    labelId="asset-type-label"
                    id="asset-type-select"
                    name="assetType"
                    className="itemForm"
                    variant='outlined'
                    defaultValue={formState.assetType || ''}
                    value={formState.assetType || ''}
                    label="Asset Type"
                    onChange={updateItem}
                    style={{fontFamily: 'Noto Sans'}}
                >
                    {
                        assetTypes.map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </Grid>
        :
        <Grid item className='editableField'>
            <TextField id="item-asset-type" label="Asset Type" className="itemForm" variant="outlined" value={formState.assetType || ''}
                fullWidth autoFocus={!!onChangeCallback} />
        </Grid>;

    var crossFunctionalTeam = isFormEditable ?
        <Grid item className='editableField'>
            <FormControl fullWidth variant="standard" error={(displayErrors || errors.crossFunctionalTeam.initialEntry) && !errors.crossFunctionalTeam.valid} required>
                <InputLabel id="cross-functional-team-label" sx={{pl: 2}}>Cross Functional Team</InputLabel>
                <Select
                    labelId="cross-functional-team-label"
                    id="cross-functional-team-select"
                    name="crossFunctionalTeam"
                    className="itemForm"
                    variant='outlined'
                    defaultValue={formState.crossFunctionalTeam || ''}
                    label="Cross Functional Team"
                    onChange={updateItem}
                    style={{fontFamily: 'Noto Sans'}}
                >
                    {
                        crossFunctionalTeams.map(team => <MenuItem key={team} value={team}>{team}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </Grid>
        :
        <Grid item className='editableField'>
            <TextField id="item-cross-functional-team" label="Cross Functional Team" className="itemForm" variant="outlined" value={formState.crossFunctionalTeam || ''}
                fullWidth autoFocus={!!onChangeCallback} />
        </Grid>; 

    var plant = isFormEditable ?
        <Grid item className='editableField'>
            <FormControl fullWidth variant="standard" error={(displayErrors || errors.plant.initialEntry) && !errors.plant.valid} required>
                <InputLabel id="plant-label" sx={{pl: 2}}>Plant</InputLabel>
                <Select
                    labelId="plant-label"
                    id="plant-select"
                    name="plant"
                    className="itemForm"
                    variant='outlined'
                    value={formState.plant || ''}
                    label="Plant"
                    onChange={updateItem}
                    style={{fontFamily: 'Noto Sans'}}
                >
                    {
                        plants.map(plant => <MenuItem key={plant} value={plant}>{plant}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </Grid>
        :
        <Grid item className='editableField'>
            <TextField id="item-plant" label="Plant" className="itemForm" variant="outlined" value={formState.plant || ''}
                fullWidth autoFocus={!!onChangeCallback} />
        </Grid>;

    var description = isFormEditable ? 
        <Grid item className='editableField'>
            <TextField id="description-text-field" label="Description" className="itemForm" variant="outlined" 
                name="description"
                value={formState.description || ''}
                onChange={updateItem}
                fullWidth multiline style={{borderRadius: '4px'}}/>
        </Grid>
        :
        <Grid item className='editableField'>
            <TextField id="description-text-field" label="Description"  className="itemForm" variant="outlined" value={formState.description || ''}
                fullWidth multiline autoFocus={!!onChangeCallback} />
        </Grid>

    var serialItem = onChangeCallback && !tagAssociated?
        <Grid item className='editableField'>
            <Grid container direction='row'>
                <Grid item>
                    <SerialScanner externalSerial={item.tagId} displayError={displayErrors} processor={processOCR} onAccept={ (serialNumber) => {
                        // setFormState({...item, tagId: serialNumber})
                        // onChangeCallback?.({ ...item, tagId: serialNumber })
                        // setErrors({...errors, tagId:{...errors.tagId, valid: validateText(serialNumber)}})
                    } } />
                </Grid>
            </Grid>
        </Grid>
        :null;

    var barcodeItem = onChangeCallback && !tagAssociated?
        <Grid item className='editableField'>
            <Grid container direction='row'>
                <Grid item flexGrow={1}>
                    <TextField id="item-barcode" label="Barcode" className="itemForm" variant="outlined" value={formState.tagId || ''} error={(displayErrors || errors.tagId.initialEntry) && !errors.tagId.valid} required
                        onChange={updateItem}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <QrCodeScannerIcon />
                                </InputAdornment>
                            ),
                        }} fullWidth />
                </Grid>
                {formState && (formState.tagType === "NewBarcode" || formState.tagTypeName === "NewBarcode") ?
                <Grid item flexGrow={0} alignSelf="end">
                    <Button sx={{ marginTop: 'auto' }} onClick={generateBarcode} disabled={!onChangeCallback}>Regenerate</Button>
                </Grid>
                : <></>
                }
                <Grid item>
                    <canvas ref={previewRef} width={0} height={0}/>
                </Grid>
            </Grid>
        </Grid>
        
    : 
        <Grid item className='editableField'>
            <TextField id="item-barcode" label="Barcode" className="itemForm" variant="outlined" value={formState.tagId || ''}
                fullWidth autoFocus={!!onChangeCallback} />
        </Grid>

    var tagTypeItem = onChangeCallback ? 
    !tagAssociated && formState.assetType !== "HEI"?
        <Grid item className='editableField'>
            <FormControl fullWidth variant="standard" error={(displayErrors || errors.tagType.valid) && !errors.tagType.valid} required>
                <InputLabel id="tag-type-label" sx={{pl: 2}}>Tag Type</InputLabel>
                <Select
                    labelId="tag-type-label"
                    id="tag-type-select"
                    name="tagType"
                    className="itemForm"
                    variant='outlined'
                    value={formState.tagType || ''}
                    label="Tag Type"
                    onChange={updateItem}
                    style={{fontFamily: 'Noto Sans'}}
                >
                    <MenuItem value="NewBarcode">New Barcode</MenuItem>
                    <MenuItem value="ExistingBarcode">Existing Barcode</MenuItem>
                    <MenuItem value="Satellite">Satellite</MenuItem>
                    <MenuItem value="LTE">LTE</MenuItem>
                    <MenuItem value="Bluetooth">Bluetooth</MenuItem>
                    <MenuItem value="Serial Number">Serial Number</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        : <></>
    :
        <Grid item className='editableField'>
            <TextField id="item-tag-type" label="Tag Type" className="itemForm" variant="outlined" value={formState.tagTypeName || formState.tagType || ''}
                fullWidth autoFocus={!!onChangeCallback && !tagAssociated} />
        </Grid>
    ;

    var satelliteItem =  onChangeCallback && !tagAssociated? 
        <Grid item className='editableField'>
            <FormControl fullWidth variant="standard" error={(displayErrors || errors.tagType.initialEntry) && !errors.tagType.valid} required>
                <InputLabel id="tag-satellite-label" sx={{pl: 2}}>Satellite Tag</InputLabel>
                <Select
                    labelId="tag-satellite-label"
                    id="tag-satellite-select"
                    name="tagId"
                    className="itemForm"
                    variant='outlined'
                    value={formState.tagId || ''}
                    label="Satellite Tag"
                    onChange={updateItem}
                    style={{fontFamily: 'Noto Sans'}}
                >
                    {
                        satelliteTags.map(satellite => <MenuItem key={satellite} value={satellite}>{satellite}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </Grid> 
    : 
        <Grid item className='editableField'>
            <TextField id="tag-satellite-label" label="Satellite Tag" className="itemForm" variant="outlined" value={formState.tagId || ''}
                fullWidth autoFocus={!!onChangeCallback} />
        </Grid>
    
    var lteItem = onChangeCallback && !tagAssociated?
        <Grid item className='editableField'>
            <FormControl fullWidth variant="standard" error={(displayErrors || errors.tagType.initialEntry) && !errors.tagType.valid} required>
                <InputLabel id="tag-lte-label" sx={{pl: 2}}>LTE Tag</InputLabel>
                <Select
                    labelId="tag-lte-label"
                    id="tag-lte-select"
                    name="tagId"
                    className="itemForm"
                    variant="outlined"
                    value={formState.tagId || ''}
                    label="LTE Tag"
                    onChange={updateItem}
                    style={{fontFamily: 'Noto Sans'}}
                >
                    {
                        lteTags.length > 0 && lteTags.map(lte => <MenuItem key={lte} value={lte}>{lte}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </Grid>
        :
        <Grid item className='editableField'>
            <TextField id="tag-lte-label" label="LTE Tag" className="itemForm" variant="outlined" value={formState.tagId || ''}
                fullWidth autoFocus={!!onChangeCallback} />
        </Grid>

    var bluetoothItem = onChangeCallback && !tagAssociated?
    <Grid item className='editableField'>
        <FormControl fullWidth variant="standard" error={(displayErrors || errors.tagType.initialEntry) && !errors.tagType.valid} required>
            <InputLabel id="tag-bluetooth-label" sx={{pl: 2}}>Bluetooth Tag</InputLabel>
            <Select
                labelId="tag-bluetooth-label"
                id="tag-bluetooth-select"
                name="tagId"
                className="itemForm"
                variant='outlined'
                value={formState.tagId || ''}
                label="Bluetooth Tag"
                onChange={updateItem}
                style={{fontFamily: 'Noto Sans'}}
            >
                {
                    bluetoothTags.length > 0 && bluetoothTags.map(bluetooth => <MenuItem key={bluetooth} value={bluetooth}>{bluetooth}</MenuItem>)
                }
            </Select>
        </FormControl>
    </Grid>
    :
    <Grid item className='editableField'>
        <TextField id="tag-bluetooth-label" label="Bluetooth Tag" className="itemForm" variant="outlined" value={formState.tagId || ''}
            fullWidth autoFocus={!!onChangeCallback} />
    </Grid>

    var visitedItem = isVisitable(item) ?
        <Grid item className='editableField'>
            <Grid container direction='row'>
                <Grid item flexGrow={1}>
                    <TextField id="item-visited-timestamp" label="Last Visited" className="itemForm" variant="outlined" value={formatTimestamp(formState.visitedTimestamp) || ''} fullWidth />
                </Grid>
            </Grid>
        </Grid> 
        : null

    if(isFormEditable){
        return <Grid sx={{borderBottom: 1, borderBottomColor: '#DADCE0', paddingBottom: 1}} container direction='column' spacing={3} className='inventoryItemGrid'>
        <Grid item>
            {
                formType == InventoryItemFormType.Update ?
                    <>
                        <Typography  variant="h5" sx={{mb: 2, fontFamily: 'Noto Sans', fontSize: '18px', fontWeight: 700}}>Edit an Asset</Typography>
                        {valid && !valid.valid ?
                            <Box key={valid.message} sx={{color: 'red'}}>{valid.message}</Box>
                        : <></>
                        }     
                        </>
                : <></>
            }
            <TextField id="item-name" label="Name" className="itemForm" variant="outlined"
                       name="name"
                       value={formState.name || ''}
                       onChange={updateItem}
                       disabled={!isFormEditable}
                       fullWidth
                       autoFocus={!!onChangeCallback}
                       required 
                       error={(displayErrors || errors.name.initialEntry) && !errors.name.valid}/>
        </Grid>
        {assetType}
        {crossFunctionalTeam}
        {plant}
        {visitedItem}
        {tagTypeItem}
        { formState.tagType === 'ExistingBarcode' && <Grid sx={(theme) => ({
                                        pl: 4, pt:2, maxWidth: '50%',
                                        [theme.breakpoints.down("sm")]: {
                                            maxWidth: 'none'
                                        }
                                    })
                                }><div id="scanner"></div></Grid> }
        { item.tagType && item.tagType.includes('Barcode') ? barcodeItem: null}
        { item.tagType === 'Serial Number' || item.tagTypeName === 'Serial Number' ? serialItem : null}
        { item.tagTypeName === 'Satellite' || item.tagType === 'Satellite' ? satelliteItem : null}
        { item.tagTypeName === 'LTE' || item.tagType === 'LTE' ? lteItem : null }
        { item.tagTypeName === 'Bluetooth' || item.tagType === 'Bluetooth' ? bluetoothItem : null }
        {description}
        <Grid item>
            <InventoryFormActions onViewMap={onViewMap} 
                isFormEditable={isFormEditable} setFormEditable={setFormEditable} formSubmitCallback={saveUpdate}
                isVisitable={isVisitable(item)} onVisited={updateVisit} tagIsBarcode={tagIsBarcode(item)} onPrintBarcode={printBarcode} onDeleteItem={deleteItem} 
                tagAssociated={tagAssociated} onRemoveTag={onRemoveTag} isHEIAsset={isHEIAsset(item)} onResetVisited={onVisitedReset}/>
        </Grid>
    </Grid>;
    }
    else{
        return <></>;
    }
}

export default InventoryItemForm;


export function randomBarcode(size: number) {
    let result = '';
    const characters = '0123456789';
    for (let i = 0; i < size; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}