import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button } from "@mui/material"
import { InventoryItem } from './DataService';

export interface InventoryFormActionProps {
    onViewMap?: () => void,
    isFormEditable?: boolean,
    setFormEditable?:  Dispatch<SetStateAction<boolean>>,
    formMode?: string,
    formSubmitCallback?: () => void
    onVisited?: () => void,
    isVisitable?: boolean,
    tagIsBarcode?: boolean,
    onPrintBarcode?: () => void,
    onDeleteItem?: () => void,
    tagAssociated?: boolean,
    onRemoveTag?: () => void,
    isHEIAsset? : boolean,
    onResetVisited?: (asset: InventoryItem | undefined) => void
}

function InventoryFormActions ({ onViewMap, isFormEditable, setFormEditable, formMode, formSubmitCallback, onVisited, isVisitable, tagIsBarcode, onPrintBarcode, onDeleteItem, tagAssociated, onRemoveTag, isHEIAsset, onResetVisited}: InventoryFormActionProps) {

    const update = () => {
        setFormEditable?.(false)
        formSubmitCallback?.()
    }

    const edit = () => {
        setFormEditable?.(true)
    }

    return (
        <Box>
        {
            onViewMap
                ? <>
                    { 
                        isFormEditable ? 
                            <Button sx={{mr: 1, mt: 1}} variant='contained' onClick={update} className='inventoryButtonClass'>Update</Button>
                            : <Button sx={{mr: 1, mt: 1}} variant='contained' onClick={edit} className='inventoryButtonClass'>Edit Asset</Button>
                    }
                    {
                        isVisitable ? 
                            <Button sx={{mr: 1, mt: 1}} variant='contained' onClick={onVisited} className='inventoryButtonClass'>Visited</Button> : null
                    }
                    { 
                        tagIsBarcode ? 
                            <Button sx={{mr: 1, mt: 1}} variant='contained' onClick={onPrintBarcode} className='inventoryButtonClass'>Print Barcode</Button> : null
                    }
                    {
                        <Button sx={{mr: 1, mt: 1}} variant='contained' onClick={onDeleteItem} color='warning' className='deleteButtonClass'>Delete</Button>
                    }
                    {
                        tagAssociated ?
                            <Button sx={{mr: 1, mt: 1}} variant='contained' onClick={onRemoveTag} color='warning' className='deleteButtonClass'>Remove Tag</Button> : null
                    }
                    {
                        isHEIAsset ?
                            <Button sx={{mr: 1, mt: 1}} variant='contained' onClick={e => {onResetVisited?.(undefined)}} color='warning' className='deleteButtonClass'>Reset Visited</Button> : null
                    }
                </>
                : null
        }
        </Box>
    )
}

export default InventoryFormActions