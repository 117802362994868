import { Avatar, Box, Button, Card, CardActions, CardContent, CardProps, Collapse, Container, IconButton, IconButtonProps, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Typography, styled } from "@mui/material";
import React from "react";
import DataService, { InventoryItem } from "./DataService";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachmentIcon from '@mui/icons-material/Attachment';

interface AttachmentsProps {
    item: InventoryItem,
    dataService?: DataService,
}

function Attachments ({item, dataService}: AttachmentsProps) {
    const SMARTS_API_BASE_URL = process.env.REACT_APP_SMARTS_API_BASE_URL;
    const [pdfs, setPDFs] = React.useState<any[]>([]);

    React.useEffect(() => {
        dataService?.getAssetFilesAsset(item.id)
            .then(res => {
                let files:any[] = [];
                for (var i = 0; i < res.length; i++) {
                    let filename = res[i].filename;
                    files.push({
                        path: `${item.id}/${filename}`,
                        filename: filename
                    });
                }
                setPDFs(files);
            });
    }, [dataService, item.id]);

    const removePDF = (file: any)  => {
        dataService?.deleteAssetFile(item.id, file.filename);
        setPDFs(pdfs.filter(p => p !== file));
    }

    const addPDF = (e: any) => {
        let reqData: any[] = [];
        let clientData: any[] = [];
        for (var i = 0; i < e.target.files.length; i++) {
            let f = e.target.files[i];
            console.log(f);
            reqData.push(f);
            clientData.push({
                path: `${item.id}/${f.name}`,
                filename: `${f.name}`
            });
        }
        dataService?.addAssetFiles(item.id, reqData);
        setPDFs(pdfs.concat(clientData));
    }

    return <Stack sx={{mt: 1, mb: 1, borderBottom: 1, borderBottomColor: '#DADCE0'}}>
        <Typography variant="h6" sx={{fontFamily: 'Noto Sans', fontSize: '18px', fontWeight: 700}}>Attachments</Typography>
        { pdfs.length > 0 &&
                <List component={Paper}>
                    { pdfs.map(file => (
                        <ListItem 
                            key={file.path}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={(_evt) => removePDF(file)}>
                                <DeleteIcon />
                                </IconButton>
                            }>
                            <ListItemButton role={undefined} onClick={() => window.open(`${SMARTS_API_BASE_URL}/files/${file.path}`)} dense>
                                <ListItemAvatar>
                                    <Avatar><FilePresentIcon /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={file.filename.length > 25 
                                    ? file.filename.substring(0, 22) + '...'
                                    : file.filename}></ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
        }
        <input type="file" accept="application/pdf" onChange={addPDF} style={{display: 'none'}} id="upload-pdf" multiple />
        <label style={{ width: '100%' }} htmlFor="upload-pdf" className="buttonLabelClass">
            <Button variant="contained" sx={{ mr: 1, mt: 1, mb: 1, width: '100%' }} component="span" startIcon={<AttachmentIcon/>} className="buttonClass">Attach PDF</Button>
        </label>
    </Stack>;
}

export default Attachments;