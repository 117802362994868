import { json } from "stream/consumers"

export interface InventoryItem {
    id: number
    name: string
    description: string | null
    tagId: string
    latitude: number
    longitude: number
    tagType: 'NewBarcode' | 'ExistingBarcode' | 'Satellite' | 'Serial Number' | 'LTE' | 'Bluetooth'
    tagTypeName?: string
    assetType: string
    crossFunctionalTeam?: string
    plant?: string
    visitedTimestamp?: string
    imageId?:number
}

export interface OrderLineItem {
    invId: number
    invName: string
    quantity: number
    exposureLevel: string
}

export interface Order {
    id: number
    status: string
    workOrder: string
    businessOrg: string
    startDate: Date
    endDate: Date
    lineItems: OrderLineItem[]
}

export interface Tag {
    id: number
    tagId: string
    deviceId: string
    tagType: string
    assetId: string
    lastUpdate: string
}

export interface PairedAssetStatus {
    paired: number[],
    unpaired: number[]
}

export type NewInventoryItem = Omit<InventoryItem, 'id'>;
export type NewOrder = Omit<Order, 'id'>;

export class DataService {
    private readonly SMARTS_API_BASE_URL = process.env.REACT_APP_SMARTS_API_BASE_URL;
    // private readonly SMARTS_API_BASE_URL = "https://smartsapi.westus2.cloudapp.azure.com:8443";

    // private localStorageKey: string;
    private inventory: InventoryItem[] = [];
    private orders: Order[] = [];
    private lastId: number = -1;
    // const [data, setData] = useState([]);

    constructor() {

    }

    async loadFromServerApi() {
        console.log(`making GET request to ${this.SMARTS_API_BASE_URL}`);

        await fetch(`${this.SMARTS_API_BASE_URL}/api/assets`)
            .then(response => response.json())
            .then(data => {
                this.inventory = [];

                console.log("Parsing data");

                // {id: 1, name: 'DUMP CHUTES 15-1C-3-A', barcode: '112940', location: '57.00339499066,-111.493191361', quantity: 10},
                data.forEach((element: any) => {
                    console.log(`parsing element: ${element}`);

                    var inventoryItem = { 
                        id: element.assetId, 
                        name: element.name, 
                        description: element.description,
                        tagId: element.tagId, 
                        tagType: element.tagType,
                        tagTypeName: this.getTagTypeName(element), 
                        latitude: Number.parseFloat(element.latitude),
                        longitude: Number.parseFloat(element.longitude),
                        assetType: element.assetType,
                        crossFunctionalTeam: element.crossFunctionalTeam,
                        plant: element.plant,
                        visitedTimestamp: element.visitedTimestamp,
                        imageId: element.imageId
                     };

                    this.inventory.push(inventoryItem);

                    // this.inventory.push(inventoryItem);
                });

                // console.log(`Inventory: ${JSON.stringify(this.inventory)}`);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    async loadCrossFunctionalTeams(): Promise<string[]> {
        let crossFunctionalTeams: string[] = [];
        
        await fetch(`${this.SMARTS_API_BASE_URL}/api/config/cross-functional-teams`).
            then(response => response.json()).
            then(data => {
                crossFunctionalTeams = data.crossFunctionalTeams
            })

        return crossFunctionalTeams.sort();
    }

    async loadUnassignedSatellites(): Promise<string[]> {
        let satellites: string[] = []

        await fetch(`${this.SMARTS_API_BASE_URL}/api/tags/unassigned/satellite`).
            then(response => response.json()).
            then(data => satellites = data)

        return satellites.sort()
    }

    async loadUnassignedLteTags(): Promise<string[]> {
        let lteTags: string[] = [];

        await fetch(`${this.SMARTS_API_BASE_URL}/api/tags/unassigned/lte`)
            .then(response => response.json())
            .then(data => {
                lteTags = data;
            });

        return lteTags;
    }

    async loadUnassignedBluetoothTags(): Promise<string[]> {
        let bluetoothTags: string[] = [];

        await fetch(`${this.SMARTS_API_BASE_URL}/api/tags/unassigned/bluetooth`)
            .then(response => response.json())
            .then(data => {
                bluetoothTags = data;
            });

        return bluetoothTags;
    }

    async loadPlants(): Promise<string[]> {
        let plants: string[] = [];

        await fetch(`${this.SMARTS_API_BASE_URL}/api/config/plants`).
            then(response => response.json()).
            then(data => {
                plants = data.plants
            })

        return plants.sort();
    }

    async loadAssetTypes(): Promise<string[]> {
        let types: string[] = [];

        await fetch(`${this.SMARTS_API_BASE_URL}/api/config/asset-types`).
            then(response => response.json()).
            then(data => {
                types = data.assetTypes
            })

        return types.sort();
    }

    getTagTypeName = (item: InventoryItem): string => {
        var tagType: string = ''

        if (item.tagType == 'ExistingBarcode') tagType = "Barcode"
        else if (item.tagType == 'NewBarcode') tagType = "Barcode"
        else tagType = item.tagType

        return tagType
    }

    async saveToDB(newItem: InventoryItem) {
        var tagType = this.getTagTypeName(newItem)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                assetId: newItem.id,
                name: newItem.name,
                description: newItem.description,
                tagId: newItem.tagId,
                tagType: tagType,
                latitude: newItem.assetType === "HEI" ? 0 : newItem.latitude,
                longitude: newItem.assetType === "HEI" ? 0 : newItem.longitude,
                altitude: null,
                assetType: newItem.assetType,
                crossFunctionalTeam: newItem.crossFunctionalTeam,
                plant: newItem.plant })
        };

        await fetch(`${this.SMARTS_API_BASE_URL}/api/assets`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(newItem.assetType !== "HEI"){
                    newItem.id = data.assetId;
                    console.log(`Inserted new item with assetId = ${newItem.id}`)
                    this.addAssetPosition(newItem);
                }
            });
    }

    addAssetPosition(newItem: InventoryItem) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ assetId: newItem.id, eventTimeUTC: new Date().toISOString(), latitude: newItem.latitude, longitude: newItem.longitude, altitude: null, receiveTime: new Date().toISOString() })
        };

        fetch(`${this.SMARTS_API_BASE_URL}/api/assets/addPosition`, requestOptions)
            .then(response => response.json())
    }

    async addAssetImage(assetId: number, assetType: string, image: String, latitude: number, longitude: number) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ assetId: assetId, time: new Date().toISOString(), assetType: assetType, image: image, latitude: latitude, longitude: longitude })
        };

        console.log(`posting to ${this.SMARTS_API_BASE_URL}/api/images`);

        let retVal = null;

        await fetch(`${this.SMARTS_API_BASE_URL}/api/images`, requestOptions)
            .then(response => response.json()).then(data => {
                retVal = {
                    image_url: `${this.SMARTS_API_BASE_URL}/api/images/gallery/asset/image?id=${data.id}`,
                    thumbnail_url: `${this.SMARTS_API_BASE_URL}/api/images/gallery/asset/thumbnail?id=${data.id}`
                };
            });
        
        return retVal;
    }

    async addAssetPairing(assetId: number, children: number[]) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "core_asset_id": assetId,
                "child_asset_id": children
            })
        };
        await fetch(`${this.SMARTS_API_BASE_URL}/api/assets/pairAsset`, requestOptions);
    };

    deleteAssetPairing(assetId: number, children: number[]) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "core_asset_id": assetId,
                "child_asset_id": children
            })
        };
        fetch(`${this.SMARTS_API_BASE_URL}/api/assets/unpairAsset`, requestOptions);
    }

    async getChildAssets(assetId: number) {
        let children: any[] = [];
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        await fetch(`${this.SMARTS_API_BASE_URL}/api/assets/childAssets?assetId=${assetId}`, requestOptions).then(response => response.json()).then(data => {
            data?.childAssetIDs?.forEach((element: any) => {
                children.push(element)
            })
        }).catch((e:any) => {
            console.log('No child asset.')
        });

        return children;
    };

    async getPairedAssetStatus(): Promise<PairedAssetStatus> {
        let pairedStatus: PairedAssetStatus = {
            paired: [],
            unpaired: []
        }

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json'}
        }

        await fetch(`${this.SMARTS_API_BASE_URL}/api/assets/pairedAssetStatus`, requestOptions).then(res => res.json()).then(data => {
            data?.pairedStatus?.paired?.forEach((element: any) => pairedStatus.paired.push(element))
            data?.pairedStatus?.unpaired?.forEach((element: any) => pairedStatus.unpaired.push(element))
        })

        return pairedStatus;
    }

    async getUnpairedAssets() {
        let children: any[] = [];
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        await fetch(`${this.SMARTS_API_BASE_URL}/api/assets/unpairedAssets`, requestOptions).then(response => response.json()).then(data => {
            data?.assetIds?.forEach((element: any) => {
                children.push(element)
            });
        }).catch((e:any) => {
            console.log(`No unpaired Assets.`)
        });

        return children;
    }

    addAssetFiles(assetId: number, files: any[]) {
        const dataForm = new FormData();
        files.forEach(pdf => {
            dataForm.append('file', pdf);
        });

        const requestOptions = {
            method: 'POST',
            body: dataForm
        };
        console.log('adding pdf', assetId, requestOptions);
        fetch(`${this.SMARTS_API_BASE_URL}/api/files/${assetId}`, requestOptions);
    }

    async getAssetFilesAsset(assetId: number) {
        let res = await fetch(`${this.SMARTS_API_BASE_URL}/api/files/${assetId}`);
        return await res.json();
    }

    async deleteAssetFile(assetId: number, filename: string) {
        const requestOptions = {
            method: 'DELETE',
        };
        console.log('deleting pdf', assetId, requestOptions);
        fetch(`${this.SMARTS_API_BASE_URL}/api/files/${assetId}/${filename}`, requestOptions);
    }

    async runEyesOnIt(assetId: number, image: string) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ assetId: assetId, time: new Date().toISOString(), assetType: undefined, image: image, latitude: undefined, longitude: undefined })
        };

        console.log(`posting to ${this.SMARTS_API_BASE_URL}/api/images/eyes-on-it`);

        const response = await fetch(`${this.SMARTS_API_BASE_URL}/api/images/eyes-on-it`, requestOptions);
        let data = await response.json();
        return data;
    }

    async getAssetImage(assetId: number): Promise<any[]> {
        let images: any[] = []
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        console.log(`requesting ${this.SMARTS_API_BASE_URL}/api/images/?assetId=${assetId}`)
        await fetch(`${this.SMARTS_API_BASE_URL}/api/images/?assetId=${assetId}`).then(response => response.json()).then(data => {
            data.forEach((element: any) => {
                images.push(element)
            })
        })

        return images
    }

    async getAssetThumbnails(assetId: number): Promise<any[]> {
        let images: any[] = []
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        console.log(`requesting ${this.SMARTS_API_BASE_URL}/api/images/thumbnails?assetId=${assetId}`)
        await fetch(`${this.SMARTS_API_BASE_URL}/api/images/thumbnails?assetId=${assetId}`).then(response => response.json()).then(data => {
            data.forEach((element: any) => {
                images.push(element)
            })
        })

        return images
    }

    async addInventoryItem(_newItem: NewInventoryItem): Promise<InventoryItem> {
        const newItem = _newItem as InventoryItem;
        newItem.tagTypeName = this.getTagTypeName(newItem);
        this.inventory = this.inventory.concat([newItem]);
        await this.saveToDB(newItem);
        return newItem;
    }

    updateInventoryItem(item: InventoryItem) {
        this.inventory = this.inventory.map(i => i.id === item.id ? item : i);
        this.saveToDB(item);
    }

    updateVisited(item: InventoryItem) {
        this.inventory = this.inventory.map(i => i.id === item.id ? item : i);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                assetId: item.id,
                timestamp: item.visitedTimestamp })
        };

        fetch(`${this.SMARTS_API_BASE_URL}/api/assets/visited`, requestOptions).catch(e => console.error(e.message))
    }

    removeInventoryItem(id: number, tagType: string) {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ assetId: id, tagType: tagType })
        };

        fetch(`${this.SMARTS_API_BASE_URL}/api/assets`, requestOptions);            

        this.inventory = this.inventory.filter(i => i.id !== id);
    }

    listInventoryItems() {
        // console.log(`Listing items. Inventory: ${JSON.stringify(this.inventory)}`);
        return this.inventory;
    }

    findInventoryItemByTagId(tagId: string) {
        return this.inventory.find(i => i.tagId === tagId);
    }

    findInventoryItemById(id: number): InventoryItem | undefined {
        return this.inventory.find(i => i.id === id);
    }

    async findTagById(id: string): Promise<Tag>  {
        const requestOptions = {
            method: 'GET'
        };

        let res = await fetch(`${this.SMARTS_API_BASE_URL}/api/tags/${id}`, requestOptions);
        return await res.json()
    }

    // order methods are no longer used
    addOrder(_newOrder: NewOrder): Order {
        const newOrder = _newOrder as Order;
        newOrder.id = ++this.lastId;
        this.orders = this.orders.concat([newOrder]);
        return newOrder;
    }

    // order methods are no longer used
    updateOrder(order: Order) {
        this.orders = this.orders.map(o => o.id === order.id ? order : o);
    }

    // order methods are no longer used
    removeOrder(id: number) {
        this.orders = this.orders.filter(i => i.id !== id);
    }

    // order methods are no longer used
    listOrders() {
        return this.orders;
    }

    async getGalleryAssetImageUrls(assetId: number): Promise<any[]> {
        let assetImages: any[] = await this.getGalleryAssetImageIds(assetId);
        let images = [];
        for(let index=0; index < assetImages.length; index++){
            let obj = {
                image_url: `${this.SMARTS_API_BASE_URL}/api/images/gallery/asset/image?id=${assetImages[index].id}`,
                thumbnail_url: `${this.SMARTS_API_BASE_URL}/api/images/gallery/asset/thumbnail?id=${assetImages[index].id}`
            }
            images.push(obj);
        }       

        return images;
    }

    async getGalleryAssetImageIds(assetId: number): Promise<any[]>{
        let images: any[] = []
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        console.log(`requesting ${this.SMARTS_API_BASE_URL}/api/images/gallery/?assetId=${assetId}`)
        await fetch(`${this.SMARTS_API_BASE_URL}/api/images/gallery/?assetId=${assetId}`).then(response => response.json()).then(data => {
            data.forEach((element: any) => {
                images.push(element)
            })
        })

        return images
    }

    async searchInventoryItem(queryStr: string): Promise<void> {
        if(queryStr != ""){
            console.log(`making GET request to ${this.SMARTS_API_BASE_URL}`);

            await fetch(`${this.SMARTS_API_BASE_URL}/api/assets/search?q=${queryStr}`)
                .then(response => response.json())
                .then(data => {                   
                    
                    this.inventory = [];
                    console.log("Parsing data");

                    data.forEach((element: any) => {

                        var inventoryItem = { 
                            id: element.asset_id, 
                            name: element.name, 
                            description: element.description,
                            tagId: element.tag_id, 
                            tagType: element.tag_type,
                            tagTypeName: this.getTagTypeName(element), 
                            latitude: Number.parseFloat(element.latest_latitude),
                            longitude: Number.parseFloat(element.latest_longitude),
                            altitude: Number.parseFloat(element.latest_altitude),
                            assetType: element.asset_type,
                            crossFunctionalTeam: element.cross_functional_team,
                            plant: element.plant,
                            visitedTimestamp: element.visited_timestamp,
                            imageId: element.image_id
                        };

                        this.inventory.push(inventoryItem);
                    });
                                    
                })
                .catch((err) => {
                    console.error(err.message);
                });
        }    
    }

    async removeTag(item: Partial<InventoryItem>): Promise<boolean>{
        let tagRemoved = false;
        if(item && item.id && item.tagId && item.tagType){
            let tagTypeName = this.getTagTypeName(item as InventoryItem);
            console.log(`Making remove Tag request.`);

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    assetId: item.id,
                    tagId: item.tagId,
                    tagType: tagTypeName
                })
            };

            let response = await fetch(`${this.SMARTS_API_BASE_URL}/api/assets/disassociateTag`, requestOptions);
            if(response){
                if(response.status === 200){
                    tagRemoved = true;
                }
            }            
        }
        
        return tagRemoved;
    }

    async updateVisitedTime(item: InventoryItem): Promise<boolean>{
        let visitedTimeReset = false;
        let assetIDs: any[] = [];
        let inventoryItem;

        if(!!item){
            if(!!item.assetType){
                if(item.id && item.assetType === 'HEI'){
                    //Find all child component ids and update visited time for all of them
                    assetIDs = await this.getChildAssets(item.id);
                }
                else if(item.id && item.assetType === 'Vent/Drain'){
                    //Just update visited time for selected asset id
                    assetIDs.push(item.id);
                }
            }
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    assetIds: assetIDs,
                    visitedTime: item.visitedTimestamp
                })
            };

            let response = await fetch(`${this.SMARTS_API_BASE_URL}/api/assets/updateVisitedTime`, requestOptions);
            if(response){
                if(response.status === 200){
                    visitedTimeReset = true;

                    //Update inventory array
                    for(let i=0; i < assetIDs.length ; i++){
                        let  index = this.inventory.findIndex(inv => inv.id === assetIDs[i]);
                        if(index !== -1){
                            this.inventory[index].visitedTimestamp = item.visitedTimestamp;
                        }
                    }
                }
            }
        }

        return visitedTimeReset;
    }
}

export default DataService;