
import Button from "@mui/material/Button";
import React from 'react';
import Webcam from "react-webcam";
import Stack from '@mui/material/Stack';
import ImageGallery from 'react-image-gallery';
import { Box, Typography } from '@mui/material';

import "./Gallery.css";
import 'react-image-gallery/styles/css/image-gallery.css';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HelpIcon from '@mui/icons-material/Help';

export type ScanItemScannerProps = {
    imageHandler?: (image: any) => void
}

function ScanItemScanner ({imageHandler}: ScanItemScannerProps ) {

    const webcamRef = React.useRef<Webcam>(null);
    const [webcamEnabled, setWebcamEnable] = React.useState<boolean>(true);
    const [images, setImages] = React.useState<any[]>([]);

    const addImage = async (image: any) => {
        setImages([ 
            { 
                "original": image,
                "thumbnail": image,
            },
            ...images
        ]);
        if (!!imageHandler) {
            imageHandler(image)
        }
    };

    const capture = () => {
        const image = webcamRef?.current?.getScreenshot();
        if (image) {
            addImage(image);
            toggleWebcam();
        }
    }

    const upload = (e: any) => {
        const reader = new FileReader();
        const file = e.target.files[0];

        reader.onloadend = () => {
            const base64string = reader.result;
            addImage(base64string);
        };

        if (file) reader.readAsDataURL(file);
    }

    const toggleWebcam = () => {
        setWebcamEnable(!webcamEnabled);  
    }

    const resetCapture = () =>{
        setImages([]);
        imageHandler?.(undefined);
        toggleWebcam();
    }

    const resetPictures = () =>{
        setImages([]);
        imageHandler?.(undefined);
    }

    React.useEffect(() => {
        console.log(`env=${process.env.NODE_ENV}`)
    },[])

    return <Stack sx={(theme) => ({
            width: '100%', height: '100%',
            [theme.breakpoints.down("sm")]: {
                pl: 0,
                pr: 0
            }
        })
    }>    
        <Box sx={{ maxWidth: 'min(80vw, 80vh)', mt: 1}}>
            { images.length > 0 && 
                <ImageGallery items={images} additionalClass="gallery" />}
            <HelpIcon sx={{color: '#195E97', fontSize: '14px', fontWeight: 400}}/><Typography variant="caption" sx={{fontFamily: 'Noto Sans', fontSize: '14px', fontWeight: 400}}>Take a picture of the asset tag or serial number.</Typography>
        </Box>   
        <Stack sx={(theme) => ({
                maxWidth: '50%', maxHeight: '50%',
                [theme.breakpoints.down("sm")]: {
                    pl: 0,
                    pr: 0,
                    maxWidth: 'none'
                }
            })
        }> 
        {webcamEnabled && <Webcam width="100%" audio={false} ref={webcamRef} screenshotFormat="image/png" videoConstraints={{facingMode: ['environment'], width:1280, height:720}} forceScreenshotSourceSize/>}     
        </Stack>
        <Box display='flex' flexDirection='row' sx={(theme) => ({
                alignItems: 'center', justifyContent: 'center', mb: 1,
                [theme.breakpoints.down("md")]: {
                    flexDirection: 'column'
                }
            })
        }>
            { webcamEnabled
                ? <>
                    <Button sx={{mr: 1, mt: 1}} onClick={capture} variant="contained" startIcon={<CenterFocusStrongIcon/>} className="buttonClass">Capture</Button>
                    <Button sx={{mr: 1, mt: 1}} onClick={toggleWebcam} variant="contained" color="error" startIcon={<CloseIcon/>} className="buttonClass">Close</Button>
                </>
                : 
                    (images.length > 0 ?
                        (process.env.NODE_ENV === "development" ?
                            <>
                                <Button sx={{mr: 1}} onClick={resetCapture} variant="contained" startIcon={<CameraAltIcon/>} className="buttonClass">Add New Picture</Button>
                                <input type="file" accept="image/*" onChange={upload} style={{display: 'none'}} id="upload-button" />
                                <label style={{ width: '100%' }} htmlFor="upload-button" className="buttonLabelClass">
                                    <Button onClick={resetPictures} variant="contained" sx={{ mr: 1, mt: 1, mb: 1, width: '100%' }} component="span" startIcon={<FileUploadIcon/>} className="buttonClass">Upload New Picture</Button>
                                </label>
                            </> 
                        : 
                            <>
                                <Button sx={{mr: 1}} onClick={resetCapture} variant="contained" startIcon={<CameraAltIcon/>} className="buttonClass">Add New Picture</Button>                           
                            </>
                        )
                    :
                        (process.env.NODE_ENV === "development" ?
                            <>
                                <Button sx={{mr: 1}} onClick={toggleWebcam} variant="contained" startIcon={<CameraAltIcon/>} className="buttonClass">Add Picture</Button>
                                <input type="file" accept="image/*" onChange={upload} style={{display: 'none'}} id="upload-button" />
                                <label style={{ width: '100%' }} htmlFor="upload-button" className="buttonLabelClass">
                                    <Button variant="contained" sx={{ mr: 1, mt: 1, mb: 1, width: '100%' }} component="span" startIcon={<FileUploadIcon/>} className="buttonClass">Upload Picture</Button>
                                </label>
                            </>
                        :
                        <>
                            <Button sx={{mr: 1}} onClick={toggleWebcam} variant="contained" startIcon={<CameraAltIcon/>} className="buttonClass">Add Picture</Button>
                        </>
                        )
                    )
            }
        </Box>
    </Stack>

}

export default ScanItemScanner