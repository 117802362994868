import {  Box, Button, Checkbox, FormControlLabel, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Modal, Paper, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import ExtensionIcon from '@mui/icons-material/Extension';
import DeleteIcon from '@mui/icons-material/Delete';
import DataService, { InventoryItem } from "./DataService";
import { SearchMenu, SearchMenuFormType, SearchState } from "./SearchMenu";
import AddIcon from '@mui/icons-material/Add';

interface ChildComponentsProps {
    item: Partial<InventoryItem>,
    dataService?: DataService,
    visitedStatus?: boolean,
    onVisitedTime?: (asset: InventoryItem | undefined) => void,
    onResetVisitedTime?: (asset: InventoryItem | undefined) => void
}

const menuStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 325,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };

function ChildComponents ({ item, dataService, visitedStatus, onVisitedTime, onResetVisitedTime}: ChildComponentsProps) {

    const [menuOpen, setMenuOpen] = React.useState(false);
    const [checked, setChecked] = React.useState<number[]>([]);
    const [children, setChildren] = React.useState<number[]>([]);
    const [unassignedAssets, setUnassignedAssets] = React.useState<InventoryItem[]>([]);
    const [pairedAssets, setPairedAssets] = React.useState<InventoryItem[]>([]);
    const [childCandidates, setChildCandidates] = React.useState<InventoryItem[]>([]);
    const [pairedChildren, setPairedChildren] = React.useState<InventoryItem[]>([]);
    
    const [searchState, setSearchState] = React.useState<SearchState>({
        searchedText: ''
    })

    React.useEffect(() => {
        let candidates: InventoryItem[] = [];
        let pairedChildren: InventoryItem[] = [];
        unassignedAssets.forEach(asset => {
            if (searchState.searchedText && searchState.searchedText.trim() !== "") {
                asset.name.toLowerCase().search(searchState.searchedText.toLowerCase()) >= 0 && candidates.push(asset);
            } else {
                candidates.push(asset);
            }
        });
        pairedAssets.forEach(asset => {
            if (searchState.searchedText && searchState.searchedText.trim() !== "") {
                asset.name.toLowerCase().search(searchState.searchedText.toLowerCase()) >= 0 && pairedChildren.push(asset);
            } else {
                pairedChildren.push(asset);
            }
        });
        setPairedChildren(pairedChildren);
        setChildCandidates(candidates);
    }, [unassignedAssets, searchState]);

    React.useEffect(() => {

        (async () => {
            await dataService?.getPairedAssetStatus()
                .then(res => {
                    let unassigned: any[] = [];
                    let paired: any[] = [];
                    res.unpaired?.forEach(id => {
                        let i = dataService?.findInventoryItemById(id);
                        if (!!item && !!item.assetType && item.assetType === "HEI"){
                            if(i && id !== item.id && i.assetType === 'Vent/Drain'){
                                unassigned.push(i);
                            }
                        }
                        else if (i && id !== item.id && i.assetType === 'Mine Component') {
                            // item exists and is not this item
                            unassigned.push(i);
                        }
                    });

                    res.paired?.forEach(id => {
                        let i = dataService?.findInventoryItemById(id);
                        if (i && id !== item.id && i.assetType === 'Mine Component') {
                            paired.push(i);
                        }
                    })
                    setUnassignedAssets(unassigned);
                    setPairedAssets(paired);
                }).catch (e => console.log('Failed to get unpaired assets'));

            item.id && await dataService?.getChildAssets(item.id)
                .then((res: any) => {
                    let childIds: number[] = [];
                    res.forEach((id: number) => childIds.push(id));
                    setChildren(childIds);
                }).catch(e => console.log('Failed to get children assets'));
        })();
    }, [dataService, item.id, searchState, menuOpen, visitedStatus]);

    const removeChild = (id: number | undefined) => {
        if (item.id && id) {
            dataService?.deleteAssetPairing(item.id, [id]);
            setChildren(children.filter(c => c !== id));
        }
    };

    const toggleChild = (id: number) => {
        const index = checked.indexOf(id);
        const newChecked = [...checked];
    
        index === -1 ? newChecked.push(id) : newChecked.splice(index, 1);
        setChecked(newChecked);
    };

    const menuAccept = async () => {
        if (item.id && checked.length > 0) {
            setChildren(children.concat(checked));
            await dataService?.addAssetPairing(item.id, checked);
        }
        menuCancel();
    };

    const menuCancel = () => {
        setMenuOpen(false);
        setSearchState({ searchedText: '' });
        setChecked([]);
    };

    const formatTimestamp = (timestamp: string | undefined) => {
        if (timestamp) {
            let date = new Date(timestamp);
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
        }
        return "Not Visited";
    }

    const switchChanged = (e:any, asset: InventoryItem) =>{        
        if(e.target.checked){        
            asset.visitedTimestamp = new Date().toISOString();
            onVisitedTime?.(asset);
        }
        else{
            asset.visitedTimestamp = undefined;
            onResetVisitedTime?.(asset);
        }
    }

    return <Stack sx={{mt: 1, mb: 1}}>
        <Typography variant="h6" sx={{fontFamily: 'Noto Sans', fontSize: '18px', fontWeight: 700}}>Components</Typography>
        {children.length > 0 && <List>            
            {children.map(id => dataService?.findInventoryItemById(id)).map(asset =>
                <ListItem 
                    key={asset?.id} 
                    component={Paper} 
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => removeChild(asset?.id)}>
                            <DeleteIcon />
                        </IconButton>
                    }>
                    <ListItemAvatar>
                        <ExtensionIcon />
                    </ListItemAvatar>
                    <ListItemText 
                        id={`${asset?.id}`} 
                        primary={`${asset?.name} • ID ${asset?.id}`}
                        secondary={`Last Visited: ${formatTimestamp(asset?.visitedTimestamp)}`} />
                    {
                        asset && !!asset.assetType && asset.assetType.trim() === "Vent/Drain"? 
                        <FormControlLabel control={<Switch checked={!!asset.visitedTimestamp && asset.visitedTimestamp.trim() !== "Not Visited" ? true : false} onChange={ e => {switchChanged(e, asset)}}/>} label={!!asset.visitedTimestamp && asset.visitedTimestamp.trim() !== "Not Visited" ? "Visited" : "Not Visited"} />                        
                        : null
                    }
                    
                </ListItem>)}
        </List>}
        <Modal
            open={menuOpen}
            onClose={menuCancel}>
            <Box sx={menuStyle}>
            { dataService && <SearchMenu searchState={searchState} formType={SearchMenuFormType.AssetTable} onKeyDown={setSearchState} onClick={setSearchState}/> }
                <List sx={{bgcolor: "background.paper", height: window.innerHeight - 300, overflow: 'auto'}}>
                    {childCandidates.map(a => 
                        <ListItem key={a.id}>
                            <ListItemButton role={undefined} onClick={() => toggleChild(a.id)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        checked={checked.indexOf(a.id) !== -1}
                                        disableRipple
                                        inputProps={{ "aria-labelledby": `${a.id}` }} />
                                </ListItemIcon>
                                <ListItemText id={`${a.id}`} primary={a.name} primaryTypographyProps={{fontFamily: 'Noto Sans', fontSize: '16px', fontWeight: 400}} />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {   
                        pairedChildren.length > 0 &&
                        <Typography variant="h6" sx={{fontFamily: 'Noto Sans', fontSize: '18px', fontWeight: 700}}>Unavailable</Typography>
                    }
                    {pairedChildren.map(a => 
                        <ListItem key={a.id}>
                            <ListItemButton role={undefined}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        checked
                                        disableRipple
                                        disabled
                                        inputProps={{ "aria-labelledby": `${a.id}` }} />
                                </ListItemIcon>
                                <ListItemText id={`${a.id}`} primary={a.name} primaryTypographyProps={{fontFamily: 'Noto Sans', fontSize: '16px', fontWeight: 400}} />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
                <Button variant="contained" sx={{ mr: 1, mt: 1, mb: 1, width: '100%' }} onClick={menuAccept} className="buttonClass">Accept</Button>
            </Box>
        </Modal>
        <Button variant='contained' sx={{ mr: 1, mt: 1, mb: 1, width: '100%' }} onClick={() => setMenuOpen(true)} startIcon={<AddIcon/>} className="buttonClass">Add Component</Button>
    </Stack>;
}

export default ChildComponents;