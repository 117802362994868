import React from 'react';
import DataService, { InventoryItem } from "../DataService";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FormControl, Grid} from '@mui/material';
import { IMapPositionContext, MapPositionContext } from '../App';
import AssetCardSummary, { AssetCardSummaryState } from './inventory/AssetCardSummary';
import ScanItemScanner from '../ScanItemScanner';


export interface ScanItemProps {
    dataService: DataService,
    onViewMap?: () => void
}

function ScanItem({dataService, onViewMap}: ScanItemProps) {

    const { setMapCenter, setMapSelection } = React.useContext<IMapPositionContext>(MapPositionContext)
    const [status, setStatus] = React.useState<string>('');
    const  [inventoryItem, setInventoryItem] = React.useState<InventoryItem | null>(null);

    const [itemExpand, setItemExpand] = React.useState<AssetCardSummaryState>({
        expand: false,
        id: -1
    });    
    const [temporaryID, setTemporaryID] = React.useState(Date.now());

    const retrieveInventory = (id: string) => {
        const item = dataService.findInventoryItemByTagId(id);
        if (item) {
            setInventoryItem(item);
            setStatus('');

            navigator.geolocation.getCurrentPosition((e) => {
                dataService.addAssetPosition({...item, latitude: e.coords.latitude, longitude: e.coords.longitude});
            });
            
        } else {
            setStatus('No matching inventory items found');
        }
    }

    const handleAssetDeletion = () =>{
        setItemExpand({expand: false, id: -1})
        resetInventoryMap()
    }

    const resetInventoryMap = () => {        
        setMapSelection?.(undefined)
        navigator.geolocation.getCurrentPosition((e) => {
            setMapCenter?.({latitude: e.coords.latitude, longitude: e.coords.longitude})
        })
    }

    const imageHandler = async (image: any) => {  
        try{
            if(image){
                setStatus('Loading...');
                let response = await dataService.runEyesOnIt(temporaryID, image); 
                let data: any;
    
                if(typeof response === "string")
                {
                    data = JSON.parse(response); 
                }  
                else if(typeof response === "object"){
                    data = response;
                }            
    
                //Handle API call to get tagId and retrieve Inventory accordingly.
                if(!!data.tag_serial_number){
                    retrieveInventory(data.tag_serial_number);
                }
                else{
                    setStatus('No matching inventory items found');
                }
            } 
            else{
                console.log(`Image reset`);
                setInventoryItem(null);
                setStatus('');
            }
        }      
        catch(err){
            setStatus('No matching inventory items found');
        }       
    }

    return <Container component={Paper} sx={(theme) => ({
                    p: 1, maxHeight: '100%', overflow: 'auto',
                    [theme.breakpoints.up("sm")]: {
                        pl: 0,
                        pr: 0,
                        maxWidth: 'none'
                    }
                })
            }>
        <FormControl fullWidth sx={{pb:1, borderBottom: 1, borderBottomColor: '#DADCE0' }}>
            <Typography variant="h5" sx={{pl:2, fontFamily: 'Noto Sans', fontSize: '18px', fontWeight: 700}}>Asset Scanning</Typography>
            <Typography variant='body1' sx={{pl:2, mb:1, color: '#777777', fontFamily: 'Noto Sans', fontSize: '12px', fontWeight: 500}}>Assets with a serial number or QR code can be scanned to learn more about the asset. When you scan the asset, this will also update the object's location on the map to your current location.</Typography>
            <Grid container direction='row' sx={{pl: 2, width: '100%'}}>
                <ScanItemScanner imageHandler={imageHandler} />
            </Grid>            
        </FormControl>
        <Typography variant="caption" color="#777">{status}</Typography>        
        {inventoryItem ? 
            <Box sx={{p: 2}}>
                <AssetCardSummary key={inventoryItem.id} item={inventoryItem} dataService={dataService} itemExpand={itemExpand} onClick={setItemExpand} onDelete={handleAssetDeletion} onViewMap={
                    () => {
                        setMapSelection?.(inventoryItem)
                        if(inventoryItem.latitude && inventoryItem.longitude) {
                            setMapCenter?.({latitude: inventoryItem.latitude, longitude: inventoryItem.longitude})
                        }

                        onViewMap?.()
                    }} />
            </Box> : null
        }        
        
    </Container>;
}

export default ScanItem;