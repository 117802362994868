import React from 'react';
import DataService, { InventoryItem, NewInventoryItem } from "../../DataService";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import InventoryItemForm, { InventoryItemFormType, randomBarcode } from "../../InventoryItemForm";
import Typography from "@mui/material/Typography";
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import DeleteIcon from '@mui/icons-material/Delete';
import Gallery, { GalleryFormType } from '../../Gallery';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AddIcon from '@mui/icons-material/Add';

function AddItem({dataService, onAdd}: { dataService: DataService, onAdd?: () => void }) {

    const classNameLookup =new Map([
        ['d10_dozer', 'Mobile Equipment - Dozer - D10'],
        ['d11_dozer', 'Mobile Equipment - Dozer - D11'],
        ['d8t_dozer', 'Mobile Equipment - Dozer - D8'],
        ['equipment_trailer', 'Mobile Equipment - Equipment Trailer'],
        ['excavators', 'Mobile equipment - Excavator'],
        ['forklifts', 'Mobile equipment - Forklift'],
        ['fuel_and_lube_trucks', 'Mobile equipment - Fuel and Lube Truck'],
        ['heat_exchanger', 'Mobile equipment - Heat Exchanger'],
        ['loaders', 'Mobile equipment - Loader'],
        ['pipelayers', 'Mobile equipment - Pipelayer'],
        ['wheel_dozer', 'Mobile equipment - Wheel Dozer']
    ]);

    const [temporaryID, setTemporaryID] = React.useState(Date.now());
    const [pdfs, setPDFs] = React.useState<any[]>([]);
    const [item, setItem] = React.useState<Partial<NewInventoryItem>>({
            //tagId: randomBarcode(12),
            tagId: '',
            latitude: 0,
            longitude: 0,
        })
    const [images, setImages] = React.useState<any[]>([]);

    const [displayErrors, setDisplayErrors] = React.useState<boolean>(false)
    const [valid, setValid] = React.useState({
        valid: true,
        hasImage: false,
        messages: [] as string[]
    })

    function validate(errors: {valid: boolean, messages: string[], hasImage?: boolean}) {
        const pictureMessage = `Asset require at least two images.`;
        var hasImage = images.length >= 1;

        if ((!!item && !!item.assetType && item.assetType !== "HEI") && images.length < 1 && !errors.hasImage) {
            // add image error message
            if (errors.messages.indexOf(pictureMessage) === -1) {
                errors.messages.push(pictureMessage);
            }
            hasImage = false;
        }
        else {
            // remove image error message
            var index = errors.messages.indexOf(pictureMessage);

            if (index > -1) {
                errors.messages.splice(index, 1);
            }
            hasImage = true;
        }

        setValid({
            valid: errors.valid,
            hasImage: hasImage,
            messages: errors.messages
        })
    }

    const addPDF = (e: any) => {
        let files: any[] = [];
        for (var i = 0; i < e.target.files.length; i++) {
            files.push(e.target.files[i]);
        }
        setPDFs(pdfs.concat(files));
    }

    const removePDF = (file: any)  => {
        setPDFs(pdfs.filter(p => p !== file));
    }

    const addItem = async () => {

        console.log(`addItem: valid.valid = ${valid.valid}`);

        if(valid.valid && valid.hasImage) {
            setDisplayErrors(false);
            dataService.addInventoryItem(item as NewInventoryItem)
                .then((newItem: InventoryItem) => {
                    if(!!newItem && !!newItem.assetType && newItem.assetType !== "HEI"){
                        images.forEach(img => {
                            console.log('adding ', newItem.id, newItem.hasOwnProperty('id'), newItem);
                            dataService.addAssetImage(newItem.id, newItem.assetType, img, newItem.latitude, newItem.longitude);
                        });
                    }
                    dataService.addAssetFiles(newItem.id, pdfs);
                });
            onAdd?.();
        } else {
            setDisplayErrors(true)
        }
    };

    const imageHandler = async (image: any) => {
        setImages([...images, image]);
        validate({ valid: valid.valid, messages: valid.messages, hasImage: image != null && images.length >= 1});

        let response = await dataService.runEyesOnIt(temporaryID, image);
        let data:any;
        if(typeof response === "object"){
            data = response
        }
        else if(typeof response === "string"){
            data = JSON.parse(response)
        }
        
        let respLabel= "";
        let label:any;
        if(!!data.label){
            respLabel = data.label;
            label = classNameLookup.get(respLabel);
        }
        else if(!!data.asset_type){
            respLabel = data.asset_type;
            if(!!data.asset_type_name){
                label = data.asset_type_name;
            }
            else{
                label = classNameLookup.get(respLabel);
            }
        }
        
        console.log('got label', label);
        console.log('got tag', data.unit_id);

        if (!!data.unit_id) {
            setItem({...item, 
                assetType: label, 
                tagId: data.unit_id,
                tagType: 'Serial Number'
            });
        } else {
            setItem({...item, assetType: label});
        }

        //After second Image, get tagId and update item.
        if(images.length == 1){
            //TagId should not be associated with any other asset.
            if(data?.asset_id == null){
                if(!!data.tag_type && !!data.tag_serial_number)
                {
                    setItem({...item,tagTypeName: data.tag_type, tagType: data.tag_type, tagId: data.tag_serial_number})
                }
                else{
                    console.log(`Tag details not found!`)
                }
            }
            else{
                console.log(`Tag already associated with assetId=${data?.asset_id}`)
            }
        }
    }

    return <Container component={Paper} sx={(theme) => ({
                [theme.breakpoints.up("sm")]: {
                    pl: 0,
                    pr: 0,
                    maxWidth: 'none'
                }
            })
        }>
        <Stack sx={{ml: 1, mr: 1}}>
            <Typography variant="h5" sx={{mb: 1, fontFamily: 'Noto Sans', fontSize: '18px', fontWeight: 700, pt: 2}}>New Asset</Typography>
            {(!!item && ((!!item.assetType && item.assetType !== "HEI") || item.assetType === null || item.assetType === undefined)) && <Gallery formType={GalleryFormType.Add} imageHandler={imageHandler} />}
            <InventoryItemForm dataService={dataService} item={item} onChangeCallback={setItem} onValidate={validate} displayErrors={displayErrors && !valid.valid} formType={InventoryItemFormType.AddNew}/>
            { pdfs.length > 0 && 
                <List>
                    <Typography variant="h6">Attached Files</Typography>
                    { pdfs.map(f => (
                        <ListItem 
                            key={f.name}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={(_evt) => removePDF(f)}>
                                <DeleteIcon />
                                </IconButton>
                            }>
                            <ListItemAvatar>
                                <Avatar><FilePresentIcon /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={f.name.length > 25 
                                ? f.name.substring(0, 22) + '...'
                                : f.name}></ListItemText>
                        </ListItem>
                    ))}
                </List>
            }
            <input type="file" accept="application/pdf" onChange={addPDF} style={{display: 'none'}} id="upload-pdf" multiple />
            <label htmlFor="upload-pdf" className='buttonLabelClass'>
                <Button variant="contained" sx={{ mr: 1, mt: 1, mb: 1 }} component="span" startIcon={<AttachmentIcon/>} className='buttonClass'>Attach PDF</Button>
            </label>
            <Button sx={{mr: 1, mt: 1, mb: 1}} onClick={addItem} variant="contained" startIcon={<AddIcon/>} className='buttonClass'>Submit</Button>
            { 
                displayErrors && valid.messages.map((value: string) => {
                    return <Box key={value} sx={{color: 'red'}}>{value}</Box>
                })
            }
        </Stack>
    </Container>;
}

export default AddItem;

