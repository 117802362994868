
import Box from '@mui/material/Box/Box';
import { Buffer } from 'buffer';
import Button from "@mui/material/Button";
import React from 'react';
import Webcam from "react-webcam";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ApiKeyCredentials } from '@azure/ms-rest-js';
import { ComputerVisionClient } from '@azure/cognitiveservices-computervision';
import Alert from '@mui/material/Alert';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
//import { CognitiveServicesCredentials } from '@azure/ms-rest-azure-js';

export type AzureComputerVisionCreds = {
    key: string,
    endpoint: string
}

export type SerialScannerProps = {
    enableDebug?: boolean
    azureAccess?: AzureComputerVisionCreds,
    displayError?: boolean,
    externalSerial?: string,
    processor?: (data: any) => string,
    onAccept: (e: any) => void
}

function SerialScanner ({azureAccess, enableDebug, displayError, externalSerial, processor, onAccept}: SerialScannerProps ) {

    const defaultAzureAccess = {
        key: 'c0fba0976b7d422ab1ac624b8c8b777e',
        endpoint: 'https://ocr-tests.cognitiveservices.azure.com/'
    }

    const webcamRef = React.useRef<Webcam>(null);
    const [image, setImage] = React.useState<any>(null);
    const [ocrResult, setOcrResult] = React.useState<string>(externalSerial || '');
    const [accepted, setAccepted] = React.useState<boolean>(false);
    const [tagInputProps, setTagInputProps] = React.useState<any>({});

    // alternative method of using credentials when submitting URL to Azure instead of blob
    //const cognitiveServiceCredentials = new CognitiveServicesCredentials(computerVisionKey);
    //const client = new ComputerVisionClient(cognitiveServiceCredentials, computerVisionEndpoint);
    const client = new ComputerVisionClient(
        new ApiKeyCredentials({
            inHeader: {
                      "Ocp-Apim-Subscription-Key": azureAccess ? azureAccess.key : defaultAzureAccess.key,
                      'content-type': "application/octet-stream",
                      'mime': "application/octet-stream",
                      "X-BingApis-SDK-Client": "node-SDK"
            }
        }), azureAccess ? azureAccess.endpoint : defaultAzureAccess.endpoint
    )

    const asyncTimer = (ms: number) => new Promise(res => setTimeout(res, ms));

    const convertToBlob = (screenshot: string|null|undefined, contentType: string): Blob|null => {
        if (screenshot) {
            // remove the meta information at the start of the string
            const buff = Buffer.from(screenshot.split(',')[1], 'base64');

            const arrayBuffer = new ArrayBuffer(buff.length);
            const view = new Uint8Array(arrayBuffer);
            for(let i = 0; i < buff.length; ++i) {
                view[i] = buff[i];
            }

            return new Blob([buff], {type: contentType});
        }

        return null;
    }

    const submitOperation = async (blob: Blob): Promise<string> => {
        // submits the OCR request to Azure
        let result = await client.readInStream(blob, {maxCandidates: 5, language: 'en', modelVersion: 'latest'});

        // split the operation identifier from successful request result
        return result.operationLocation.split('/').slice(-1)[0];
    }

    const requestResults = async (id: string, maxWait: number): Promise<any> => {
        
        let sleepCount = 0;
        while(sleepCount < maxWait) {
            console.log('Waiting for azure ocr result ' + sleepCount + '/' + maxWait)
            await asyncTimer(1000)

            let processResult = await client.getReadResult(id);
            if (processResult.status === 'succeeded') {
                return processResult.analyzeResult?.readResults;
            }
            
            ++sleepCount;
        }
    }

    const capture = React.useCallback( async () => {
        setAccepted(false)
        setTagInputProps({...tagInputProps, readOnly: false})

        const imageSrc = webcamRef?.current?.getScreenshot();
        
        // debug the image that was captured
        if (enableDebug && imageSrc) {
            setImage(imageSrc);
        }

        const blob = convertToBlob(imageSrc, 'image/png');
        
        // creates a URL to the local storage blob that could be used as well
        //const newURL = URL.createObjectURL(blob)
        //console.log("COMPUTER VISION BLOB " + newURL)

        if (blob) {
            try {
                let operation = await submitOperation(blob);
                let ocrResult = await requestResults(operation, 30);
                
                let result = null;
                if (processor) {
                    result = processor(ocrResult)
                }
                else {
                    result = JSON.stringify(ocrResult);
                }

                if (result && result.trim().length > 0){
                    setOcrResult(result)
                } else {                    
                    setOcrResult('Invalid Scan')
                }
            } catch (err) {
                console.log('Error processing asset scan ' + err);
            }
        }

        console.log('done');
    },
    [webcamRef])

    return <>
        {(! !!externalSerial) && <>
            <Alert severity="info">Please capture a close up image of the Serial Number.</Alert>
            <Webcam width={'100%'} audio={false} ref={webcamRef} screenshotFormat="image/png" videoConstraints={{facingMode: ['environment']}}/>
            <Button sx={{mb:1}} onClick={capture} variant="contained" startIcon={<CenterFocusStrongIcon/>} className='buttonClass'>Capture</Button>
        </>}
        <TextField id="item-serial-number" 
            label="Asset Tag" 
            variant="standard" 
            onChange={(e) => setOcrResult(e.target.value)} 
            value={ocrResult} 
            inputProps={tagInputProps}
            error={displayError && (ocrResult.trim().length <= 0)}
            fullWidth />
        {(! !!externalSerial) && <Button sx={{mt: 1}} disabled={accepted} className='buttonClass' onClick={() => {
                setAccepted(true)
                setTagInputProps({...tagInputProps, readOnly: true})
                onAccept(ocrResult)
            }} 
            variant='contained'>Accept</Button>
        }
        {
            enableDebug && image && <Box>
                <img src={image} />
            </Box>
        }
    </>

}

export default SerialScanner