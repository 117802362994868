import React, { useContext } from 'react';
import DataService, { InventoryItem, Tag } from "../../DataService";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
    AzureMapsProvider,
    AzureMap,
    AzureMapHtmlMarker,
} from 'react-azure-maps';
import { AuthenticationType, layer, source } from "azure-maps-control";
import PlaceIcon from '@mui/icons-material/Place';
import Grid from "@mui/material/Grid";
import QRCode from "qrcode";
import { FilterMenu, FilterState } from '../../FilterMenu';
import MapController from './MapController';
import { IMapPositionContext, MapPositionContext } from '../../App';
import Gallery from '../../Gallery';
import { Box, Button } from '@mui/material';
import Attachments from '../../Attachments';
import { SearchMenu, SearchMenuFormType, SearchState } from '../../SearchMenu';
import InventoryItemSummaryForm from '../../InventoryItemSummaryForm';
import AssetCardSummary, { AssetCardSummaryState } from './AssetCardSummary';
const { DateTime } = require("luxon");

export interface Point {
    latitude: number,
    longitude: number
}
export interface InventoryMapProps {
    dataService: DataService,
    defaultSelection?: InventoryItem
}

function InventoryMap({dataService, defaultSelection}: InventoryMapProps) {

    const { mapCenter, setMapCenter } = React.useContext<IMapPositionContext>(MapPositionContext)
    const [itemExpand, setItemExpand] = React.useState<AssetCardSummaryState>({
        expand: false,
        id: -1
    });
    
    const [itemFilterTypes, setItemFilterTypes] = React.useState<FilterState>({
        assetType: 'Asset Type',
        tagType: 'Tag',
        crossFunctionalTeamType: 'Team',
        plantType: 'Plant'
    })

    const [searchState, setSearchState] = React.useState<SearchState>({
        searchedText: ''
    })
    const [searchFound, setSearchFound] = React.useState<boolean>(false);


    const [currentPosition, setCurrentPosition] = React.useState<number[] | undefined>();

    const assetFilter = (item: InventoryItem | undefined) => { return itemFilterTypes.assetType === 'Asset Type' || item?.assetType?.trim()?.toLowerCase() === itemFilterTypes.assetType.trim()?.toLowerCase()}
    const tagTypeFilter = (item: InventoryItem | undefined) => { return itemFilterTypes.tagType === 'Tag' || item?.tagType?.trim()?.toLowerCase() === itemFilterTypes.tagType.trim()?.toLowerCase()}
    const crossFunctionalTeamFilter = (item : InventoryItem | undefined) => { return itemFilterTypes.crossFunctionalTeamType === 'Team' || item?.crossFunctionalTeam?.trim()?.toLowerCase() === itemFilterTypes.crossFunctionalTeamType.trim()?.toLowerCase()}
    const plantFilter = (item: InventoryItem | undefined) => { return itemFilterTypes.plantType === 'Plant' || item?.plant?.trim()?.toLowerCase() === itemFilterTypes.plantType.trim()?.toLowerCase()}

    const itemFilter = (item: InventoryItem | undefined) => {
        return assetFilter(item) && tagTypeFilter(item) && crossFunctionalTeamFilter(item) && plantFilter(item)
    }

    const barcodeRef = React.createRef<HTMLCanvasElement>()
    const [selectedItem, setSelectedItem] = React.useState<InventoryItem | undefined>(undefined)
    const [selectedTag, setSelectedTag] = React.useState<Tag | undefined>(undefined);

    React.useEffect(() => {        
        const searchInventory = async() =>{   
            if(searchState.searchedText && searchState.searchedText.trim() != ""){
                if(selectedItem){
                    setSelectedItem(undefined);
                }                
                await dataService.searchInventoryItem(searchState.searchedText.trim())
                setSearchFound(!searchFound) 
            } 
            else{
                await dataService.loadFromServerApi();
                setSearchFound(!searchFound) 
            }          
        }

        searchInventory().catch(console.error);

    },[searchState]);

    const inventoryItems = React.useMemo(() => {
        return dataService.listInventoryItems().filter(itemFilter)
    }, [dataService, itemFilterTypes, searchFound])

    const items = React.useMemo(() => inventoryItems.filter(itemFilter).map((item) => {
            const [lat, lon] = [item.latitude, item.longitude];
            if (!isNaN(lat) && !isNaN(lon) && (selectedItem === undefined || item.id !== selectedItem.id)) {
                return {id: item.id + '', name: item.name, lat, lon};
            } else {
                return null;
            }
        }).filter(item => !!item) as { id: string, name: string, lat: number, lon: number }[], 
        [inventoryItems, itemFilterTypes, selectedItem]);

    React.useEffect(() => {
        setSelectedItem(undefined);
    },[itemFilterTypes])
    
    React.useEffect(() => {
        if (selectedItem && barcodeRef.current) {
            QRCode.toCanvas(barcodeRef.current, selectedItem.tagId, {errorCorrectionLevel: 'H'}, (error) => {
                if (error) {
                    console.error(error);
                }
            });
        }
        if (selectedItem && selectedItem.tagId !== undefined) {
            (async () => {
                setSelectedTag(await dataService.findTagById(selectedItem.tagId));
            })();
        }
    }, [barcodeRef.current, selectedItem?.tagId]);

    React.useEffect(() => {
        if(defaultSelection) {
            setSelectedItem(defaultSelection)
        }
    }, [defaultSelection])

    const updatePosition = () => {
        navigator.geolocation.getCurrentPosition((e) => {
            setCurrentPosition([e.coords.longitude, e.coords.latitude])
        })
    };

    const handleCenterMapClick = () => {
        navigator.geolocation.getCurrentPosition((e) => {
            setMapCenter?.({latitude: e.coords.latitude, longitude: e.coords.longitude});
            setCurrentPosition([e.coords.longitude, e.coords.latitude]);
        })
    }

    React.useEffect(() => {
        updatePosition();
        const interval = setInterval(() => {
            updatePosition();
        }, 60_000);
        return () => {
            clearInterval(interval);
        }
    }, [])

    //const [totalLon, totalLat] = items.reduce(([totalLon, totalLat], item) =>
    //        [totalLon + item.lon, totalLat + item.lat], [0, 0]),
    //    centerLat = items.length ? (totalLat / items.length) : 0,
    //    centerLon = items.length ? (totalLon / items.length) : 0;

    return <Grid container direction="column" style={{height: '100%', padding: 0, width: '100%'}}>
        <Grid item>
            <Grid container direction="row">
                <Grid item xs={12} sm={6} md={12} component={Paper} style={{padding: 0, width: '100%'}} className='filterPaper'>
                    <SearchMenu searchState={searchState} formType={SearchMenuFormType.AssetMap} onKeyDown={setSearchState} onClick={setSearchState} onCenterMap={handleCenterMapClick}/>                    
                    <FilterMenu dataService={dataService} filterState={itemFilterTypes} onChange={setItemFilterTypes}/>
                    <Typography sx={{p:1, color: '#888888'}} variant="caption" className="itemText itemTextBody">Asset Count: {items.length}</Typography>
                </Grid>
                <Grid container direction="row">
                <Grid item xs={12} sm={6} md={12} component={Paper} sx={{minHeight:window.innerHeight - 330, padding:0, width: '100%'}} >
                    <AzureMapsProvider>
                        <>
                            <MapController />
                            <AzureMap options={{
                                authOptions: {
                                    authType: AuthenticationType.subscriptionKey as any,
                                    subscriptionKey: 'wmnk0xCLZit_1Puk4-8U_v2riNQDWMCma6ggFJm3OVs',
                                },
                                center: [mapCenter?.longitude, mapCenter?.latitude],
                                zoom: 9,
                                view: 'Auto',
                                style: 'satellite_road_labels',
                            }}>
                                <>
                                    {currentPosition && 
                                        <AzureMapHtmlMarker 
                                            options={{ position: currentPosition }} 
                                            markerContent={
                                                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="10" cy="10" r="8" fill="#4285F4" stroke="#FFF" strokeWidth="3" />
                                                </svg>
                                            }
                                        /> 
                                    }
                                    {items.map(item =>
                                        <AzureMapHtmlMarker key={item.id} id={item.id} options={{position: [item.lon, item.lat]}}
                                                            events={[{
                                                                eventName: 'click',
                                                                callback: () => setSelectedItem(selectedItem =>
                                                                    (item.id === (selectedItem?.id + ''))
                                                                        ? undefined : inventoryItems.find(i => (i.id + '') === item.id)
                                                                )
                                                            }]}
                                                            markerContent={<PlaceIcon style={{
                                                                fill: '#195E97',
                                                                stroke: 'white',
                                                                fontSize: 28,
                                                            }}/>}/>)
                                    }
                                    {selectedItem &&
                                        <AzureMapHtmlMarker 
                                            options={{position: [selectedItem.longitude, selectedItem.latitude]}}
                                            markerContent={<PlaceIcon style={{
                                                fill: '#d32f2f',
                                                stroke: 'white',
                                                fontSize: 28,
                                        }}/>}/>
                                    }   
                                </>
                            </AzureMap>
                        </>
                    </AzureMapsProvider>
                </Grid>
                </Grid>
                { selectedItem && 
                <Grid container direction="row">
                <Grid item xs={12} sm={6} md={12}>                    
                    <AssetCardSummary item={selectedItem as InventoryItem} dataService={dataService} itemExpand={itemExpand} onClick={setItemExpand} />
                </Grid>
                </Grid>
                }
            </Grid>
        </Grid>
    </Grid>;
}

export default InventoryMap;