import React from 'react';
import DataService, { InventoryItem } from "../../DataService";
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import {  GridRowsProp } from "@mui/x-data-grid";
import QRCode from "qrcode";
import Container from '@mui/material/Container';
import { FilterMenu, FilterState } from '../../FilterMenu';
import { IMapPositionContext, MapPositionContext } from '../../App';
import { SearchMenu, SearchMenuFormType, SearchState } from '../../SearchMenu';
import AssetCardSummary, { AssetCardSummaryState } from './AssetCardSummary';
import { Typography } from '@mui/material';

export interface InventoryProps {
    dataService: DataService,
    onViewMap?: () => void
}
  

function Inventory({dataService, onViewMap}: InventoryProps) {

    const { setMapCenter, setMapSelection } = React.useContext<IMapPositionContext>(MapPositionContext)

    const [rows, setRows] = React.useState<GridRowsProp>([])

    const [itemFilterTypes, setItemFilterTypes] = React.useState<FilterState>({
        assetType: 'Asset Type',
        tagType: 'Tag',
        crossFunctionalTeamType: 'Team',
        plantType: 'Plant'
    })

    const [searchState, setSearchState] = React.useState<SearchState>({
        searchedText: ''
    })

    const [itemExpand, setItemExpand] = React.useState<AssetCardSummaryState>({
        expand: false,
        id: -1
    });

    const [scrollPosition, setScrollPosition] = React.useState(0);

    const assetFilter = (item: InventoryItem | undefined) => { return itemFilterTypes.assetType === 'Asset Type' || item?.assetType?.trim()?.toLowerCase() === itemFilterTypes.assetType.trim()?.toLowerCase()}
    const tagTypeFilter = (item: InventoryItem | undefined) => { return itemFilterTypes.tagType === 'Tag' || item?.tagType?.trim()?.toLowerCase() === itemFilterTypes.tagType.trim()?.toLowerCase()}
    const crossFunctionalTeamFilter = (item : InventoryItem | undefined) => { return itemFilterTypes.crossFunctionalTeamType === 'Team' || item?.crossFunctionalTeam?.trim()?.toLowerCase() === itemFilterTypes.crossFunctionalTeamType.trim()?.toLowerCase()}
    const plantFilter = (item: InventoryItem | undefined) => { return itemFilterTypes.plantType === 'Plant' || item?.plant?.trim()?.toLowerCase() === itemFilterTypes.plantType.trim()?.toLowerCase()}

    const itemFilter = (item: InventoryItem | undefined) => {
        return assetFilter(item) && tagTypeFilter(item) && crossFunctionalTeamFilter(item) && plantFilter(item)
    }

    const sortRows = (a: InventoryItem, b: InventoryItem) => {
        if(a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
    }

    const handleAssetDeletion = () =>{
        setItemExpand({expand: false, id: -1})
        resetInventoryMap()
        refreshRows()
    }

    const resetInventoryMap = () => {        
        setMapSelection?.(undefined)
        navigator.geolocation.getCurrentPosition((e) => {
            setMapCenter?.({latitude: e.coords.latitude, longitude: e.coords.longitude})
        })
    }

    const refreshRows = () => {        
        setRows(dataService.listInventoryItems().filter(itemFilter).sort(sortRows))
    }

    const handleOnAssetClick = (e:any) =>{     
        setItemExpand({expand: e.expand, id: e.id});   
        if(e.expand){
            setScrollPosition(window.scrollY);
        }      
    }

    const onSearchTextClick = (e:any) => {
        if(scrollPosition !== 0){
            setScrollPosition(0);
        }
        setSearchState(e);
    }

    React.useEffect(() => {
        refreshRows()
    }, [dataService, itemFilterTypes]);

    React.useEffect(() => {
        if(!itemExpand.expand){
            window.scrollTo(0,scrollPosition);
        }
    },[itemExpand])

    React.useEffect(() => {
        const searchInventory = async() =>{
            if(searchState.searchedText && searchState.searchedText.trim() != ""){
                await dataService.searchInventoryItem(searchState.searchedText.trim())
                setRows(dataService.listInventoryItems().filter(itemFilter).sort(sortRows))
            } 
            else{
                await dataService.loadFromServerApi();
                setRows(dataService.listInventoryItems().filter(itemFilter).sort(sortRows))
            }            
        }

        searchInventory().catch(console.error);
    },[searchState]);

    return <Container sx={(theme) => ({
                                height: "100%",
                                pl: 0,
                                pr: 0,
                                [theme.breakpoints.up("sm")]: {
                                    pl: 0,
                                    pr: 0,
                                    maxWidth: 'none'
                                }
                            })
                        }
        >
        <Grid container direction="column" >
            {
                // Render Search bar and filters iff child componenet is not expanded
                !itemExpand.expand ?
                <Grid item component={Paper} sx={{padding: 0, width: '100%'}} className='filterPaper'>
                    <SearchMenu searchState={searchState} formType={SearchMenuFormType.AssetTable} onKeyDown={onSearchTextClick} onClick={onSearchTextClick}/>                
                    <FilterMenu dataService={dataService} filterState={itemFilterTypes} onChange={setItemFilterTypes}/>
                    <Typography sx={{p:1, color: '#888888'}} variant="caption" className="itemText itemTextBody">Asset Count: {rows.length}</Typography>
                </Grid>
                : <></>
            }
            <Grid item sx={{maxWidth: '100%'}}>
            {                
                rows && rows.map((row) => {
                    // Render all results iff child componenet is not expanded else just render selected/expanded component
                    return (
                        !itemExpand.expand ? 
                            <AssetCardSummary key={row.id} item={row as InventoryItem} dataService={dataService} itemExpand={itemExpand} onClick={handleOnAssetClick} onDelete={handleAssetDeletion} onViewMap={
                                () => {
                                    let asset = row as InventoryItem
                                    setMapSelection?.(asset)
                                    if(asset.latitude && asset.longitude){
                                        setMapCenter?.({latitude: asset.latitude, longitude: asset.longitude})
                                    }
            
                                    onViewMap?.()
                                }} />
                            :
                            (
                                itemExpand.id == row.id ?
                                    <AssetCardSummary key={row.id} item={row as InventoryItem} dataService={dataService} itemExpand={itemExpand} onClick={handleOnAssetClick} onDelete={handleAssetDeletion} onViewMap={
                                        () => {
                                            let asset = row as InventoryItem
                                            setMapSelection?.(asset)
                                            if(asset.latitude && asset.longitude){
                                                setMapCenter?.({latitude: asset.latitude, longitude: asset.longitude})
                                            }
                    
                                            onViewMap?.()
                                        }} />
                                    : <></>
                            )
                        )
                    
                    })                
            }
            </Grid>
        </Grid>
    </Container>;
}

export default Inventory;

async function printInventoryLabels(items: InventoryItem[]) {
    const iframe = (
        document.querySelector('#printInventory') || document.createElement('iframe')
    ) as HTMLIFrameElement;
    iframe.id = 'printInventory';
    iframe.style.display = 'none';
    iframe.onload = () => {
        if (iframe.contentWindow) {
            iframe.contentWindow.print();
        } else {
            console.error('Content window is null');
        }
    };

    const barcodes = await Promise.all(items.map(i => QRCode.toDataURL(i.tagId, {errorCorrectionLevel: 'H'})));

    iframe.srcdoc = `<!doctype html>
<html lang="en" style="height: 100%;">
<head>
<title>Inventory Labels</title>
</head>
<body style="height: 100%; margin: 0; padding: 0; box-sizing: border-box; font-family: sans-serif">
${items.map((item, i) => `<div style="width: 50%; display: inline-block; text-align: center; border: 1px dashed #555; box-sizing: border-box; padding-bottom: 1rem">
<img src="${barcodes[i]}" style="height: 320px; max-width: 100%" alt="${item.tagId}"><br/>
<pre style="display: block; margin-top: -2rem; font-size: 26px">${item.tagId}</pre>
${item.latitude && item.longitude ? `<span style="font-weight: bold; font-size: 21px; color: #555555;">${item.latitude}, ${item.longitude}</span>` : '<br/>'}
<p style="padding-top: 1rem;">${item.name}</p>
</div>`).join('')}
</body>
</html>`;
    if (!iframe.parentElement) {
        document.body.appendChild(iframe);
    }
}