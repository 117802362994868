import React, { Dispatch, SetStateAction } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/noto-sans';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import MapIcon from '@mui/icons-material/Map';
import Toolbar from '@mui/material/Toolbar';
import Typography from "@mui/material/Typography";
import logo from './img/suncor_logo.svg';
import Inventory from "./tabs/inventory/Inventory";
import DataService, { InventoryItem } /*, { NewOrder } */ from "./DataService";
import AddItem from "./tabs/inventory/AddItem";
import InventoryMap, { Point } from "./tabs/inventory/InventoryMap";
import ScanItem from "./tabs/ScanItem";
import Orders from "./tabs/orders/Orders";
import EditOrder from "./tabs/orders/EditOrder";

const drawerWidth = 240;
// const LOCALSTORAGE_KEY = 'INVENTORY';
type SubMenuItem = {
    id: string, icon: React.ReactElement,
    title: string, component: ({dataService}: { dataService: DataService }) => React.ReactElement
}
type MenuItem = {
    title: string, icon: React.ReactElement, subMenuItems: SubMenuItem[]
}

export interface IMapPositionContext {
    mapCenter?: Point,
    setMapCenter?: Dispatch<SetStateAction<Point>>,
    mapSelection?: InventoryItem,
    setMapSelection?: Dispatch<SetStateAction<InventoryItem | undefined>>
}
export const MapPositionContext = React.createContext<IMapPositionContext>({})

function App() {
    console.log("App()");
// add a first load flag
    const dataServiceRef = React.useRef(new DataService(/*LOCALSTORAGE_KEY*/));

    const [loading, setLoading] = React.useState<boolean>(true);
    const [currentMenuItem, setCurrentMenuItem] = React.useState<MenuItem | null>(null);
    const [currentSubMenuItem, _setCurrentSubMenuItem] = React.useState<SubMenuItem | null>(null);

    const [mapCenter, setMapCenter] = React.useState<Point>({latitude: 56.96370460, longitude: -111.43803893})
    const [mapSelection, setMapSelection] = React.useState<InventoryItem | undefined>()

    const [sideMenuOpen, setSideMenuOpen] = React.useState(false);
    const setCurrentSubMenuItem = (tab: SubMenuItem) => {
        _setCurrentSubMenuItem(tab);
        setSideMenuOpen(false);
    }

    const handleDrawerToggle = () => {
        setSideMenuOpen(!sideMenuOpen);
    };

    const menuItems: MenuItem[] = React.useMemo(() => [
            {
                title: 'Assets', icon: <TableRowsOutlinedIcon/>, subMenuItems: [
                    {
                        id: 'inventory-map', title: 'Asset Map', icon: <MapIcon/>,
                        component: () => <MapPositionContext.Provider value={{mapCenter, setMapCenter}}>
                                <InventoryMap dataService={dataServiceRef.current} defaultSelection={mapSelection}/>
                            </MapPositionContext.Provider>
                    },
                    {
                        id: 'inventory', title: 'Asset Table', icon: <TableRowsOutlinedIcon/>,
                        component: () => <MapPositionContext.Provider value={{mapCenter, setMapCenter, mapSelection, setMapSelection}}>
                                <Inventory dataService={dataServiceRef.current} onViewMap={resetMenu} />
                            </MapPositionContext.Provider>
                    },
                    {
                        id: 'scan-item', title: 'Scan Asset', icon: <QrCodeScannerIcon/>,
                        component: () => <MapPositionContext.Provider value={{mapCenter, setMapCenter, setMapSelection}}>
                                <ScanItem dataService={dataServiceRef.current} onViewMap={resetMenu}/>
                            </MapPositionContext.Provider>
                    },
                    {
                        id: 'inventory-add-item', title: 'Add Asset', icon: <AddOutlinedIcon/>,
                        component: () => <AddItem dataService={dataServiceRef.current} onAdd={resetMenu}/>
                    }
                ]
            }
            // , {
            //     title: 'Orders', icon: <TableRowsOutlinedIcon/>, subMenuItems: [
            //         {
            //             id: 'orders', title: 'Order Table', icon: <TableRowsOutlinedIcon/>,
            //             component: () => <Orders dataService={dataServiceRef.current}/>
            //         },
            //         {
            //             id: 'orders-add', title: 'New Order', icon: <AddOutlinedIcon/>,
            //             component: () =>
            //                 <EditOrder dataService={dataServiceRef.current} onSubmit={(order) => {
            //                     dataServiceRef.current.addOrder({...order, status: 'Unconfirmed'} as NewOrder);
            //                     setCurrentSubMenuItem(menuItems[1].subMenuItems[0]);
            //                 }}/>
            //         }
            //     ]
            // },
        ], [dataServiceRef, mapCenter]),
        drawer = (
            <div>
                <Toolbar>
                    <img src={logo} alt="Suncor" style={{margin: 'auto'}}/>
                </Toolbar>
                <Divider/>
                <List component="nav">
                    {menuItems.map((menuItem: MenuItem) =>
                        <ListItemButton key={menuItem.title} /*selected={currentMenuItem?.title === menuItem.title}*/
                                        onClick={() => {
                                            setCurrentMenuItem(menuItem);
                                            setCurrentSubMenuItem(menuItem.subMenuItems[0]);
                                        }}>
                            <ListItemIcon>{menuItem.icon}</ListItemIcon>
                            <ListItemText primary={menuItem.title}/>
                        </ListItemButton>)}
                    <Divider style={{marginTop: '4rem'}}/>
                    {currentMenuItem?.subMenuItems.map((subMenuItem: SubMenuItem) =>
                        <ListItemButton selected={currentSubMenuItem?.id === subMenuItem.id} key={subMenuItem.id}
                                        onClick={() => setCurrentSubMenuItem(subMenuItem)}>
                            <ListItemIcon>{subMenuItem.icon}</ListItemIcon>
                            <ListItemText primary={subMenuItem.title}/>
                        </ListItemButton>)}
                </List>
            </div>
        );

    React.useEffect(() => {
        if (dataServiceRef.current) {
            // console.log("calling loadFromServerApi");
            dataServiceRef.current.loadFromServerApi().then(() => {
                setLoading(false);
            });
        }

        setCurrentMenuItem(menuItems[0]);
        setCurrentSubMenuItem(menuItems[0].subMenuItems[0]);
    }, [dataServiceRef, menuItems]);

    const resetMenu = () => {
        setCurrentMenuItem(menuItems[0]);
        setCurrentSubMenuItem(menuItems[0].subMenuItems[0]);
    }

    const container = window.document.body;

    if (loading) {
        return (
            <div>loading positions</div>
        )
    }
    else {
        return (
            <Box sx={{display: 'flex', height: '100%'}}>
                <CssBaseline/>
                <AppBar position="fixed"
                        sx={{
                            width: {sm: `calc(100% - ${drawerWidth}px)`},
                            ml: {sm: `${drawerWidth}px`},
                            backgroundColor: '#195E97'
                        }}>
                    <Toolbar>
                        <IconButton color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{mr: 2, display: {sm: 'none'}}}>
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            {currentSubMenuItem ? currentSubMenuItem?.title : null}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box component="nav"
                    sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                    aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Drawer container={container}
                            variant="temporary"
                            open={sideMenuOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: {xs: 'block', sm: 'none'},
                                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                            }}>
                        {drawer}
                    </Drawer>
                    <Drawer variant="permanent"
                            sx={{
                                display: {xs: 'none', sm: 'block'},
                                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                            }}
                            open>
                        {drawer}
                    </Drawer>
                </Box>
                <Box component="main"
                    sx={{
                        height: '100%',
                        flexGrow: 1,
                        width: '100%', //{sm: `calc(100% - ${drawerWidth}px)`},
                        paddingTop: {xs: 7, sm: 8},
                    }}>
                    {currentSubMenuItem ? currentSubMenuItem?.component({
                        dataService: dataServiceRef.current
                    }) : null}

                </Box>
            </Box>
        )};
}

export default App;
