
import Button from "@mui/material/Button";
import React from 'react';
import Webcam from "react-webcam";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from '@mui/material/Stack';
import { ApiKeyCredentials } from '@azure/ms-rest-js';
import { ComputerVisionClient } from '@azure/cognitiveservices-computervision';
import DataService, { InventoryItem } from './DataService';
import ImageGallery from 'react-image-gallery';

import { ok } from 'assert';
import { isAssertionKey } from 'typescript';
import { Alert, Box, Container, ImageList, ImageListItem, MobileStepper, Typography } from '@mui/material';

import "./Gallery.css";
import 'react-image-gallery/styles/css/image-gallery.css';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HelpIcon from '@mui/icons-material/Help';
import defaultImg from './img/default.png';

export enum GalleryFormType{
    Add,
    Show
}

export type GalleryProps = {
    dataService?: DataService,
    item?: InventoryItem,
    formType?: GalleryFormType,
    imageHandler?: (image: any) => void,
}

function Gallery ({dataService, item, formType, imageHandler}: GalleryProps ) {

    const webcamRef = React.useRef<Webcam>(null);
    const galleryRef = React.useRef();
    const [activeStep, setActiveStep] = React.useState(0);
    const [webcamEnabled, setWebcamEnable] = React.useState<boolean>(false);
    const [thumbnails, setThumbnails] = React.useState<any[]>([]);
    const [images, setImages] = React.useState<any[]>([])
    const [displayText, setDisplayText] = React.useState("Step1: Take a picture of the entire asset.");
    const [showTitle, setShowTitle] = React.useState(formType == GalleryFormType.Show);

    React.useEffect(() => {
        const fetchData = async () => {
            if (item) {
                if(item?.imageId === null && item?.assetType === "HEI"){
                    setThumbnails([...thumbnails,{"original": defaultImg, "thumbnail": defaultImg, "loading": "lazy"}]);
                    setImages([...images,{"original": defaultImg, "thumbnail": defaultImg, "loading": "lazy"}]);
                }
                else{
                    let requestedImages = await dataService?.getGalleryAssetImageUrls(item?.id)
                    if (requestedImages) {
                        setThumbnails(requestedImages);
                        setImages(requestedImages.map(i => ({ "original": i.image_url, "thumbnail":  i.thumbnail_url, "loading" : "lazy"})))

                    }
                }
            }
        }
        fetchData().catch();
    }, [item, dataService]);

    React.useEffect(() => {
        if(images){
            if(images.length == 1){
                setDisplayText("Step 2: Take a picture of the asset tag or serial number.");
            }
            else if(images.length > 1){
                setDisplayText("Step 3: Take additional pictures of the asset from multiple angles.");
            }
        }
    },[images])

    const addImage = async (image: any) => {
        setImages([ 
            { 
                "original": image,
                "thumbnail": image,
            },
            ...images
        ]);
        if (!!imageHandler) {
            imageHandler(image)
        } else if (!!item) {
            let retVal: any = {}
            retVal = await dataService?.addAssetImage(item.id, item.assetType, image, item.latitude, item.longitude);
            if(retVal && retVal.image_url && retVal.thumbnail_url){
                setImages([ 
                    { 
                        "original": retVal.image_url,
                        "thumbnail": retVal.thumbnail_url,
                        "loading" : "lazy"
                    },
                    ...images
                ]);
            }            
        }
    };

    const capture = () => {
        const image = webcamRef?.current?.getScreenshot();
        if (image) {
            addImage(image);
        }
    }

    const upload = (e: any) => {
        const reader = new FileReader();
        const file = e.target.files[0];

        reader.onloadend = () => {
            const base64string = reader.result;
            addImage(base64string);
        };

        if (file) reader.readAsDataURL(file);
    }

    const toggleWebcam = () => {
        setWebcamEnable(!webcamEnabled);
    }


    return <Stack sx={(theme) => ({
                borderBottom: 1, borderBottomColor: '#DADCE0',
                [theme.breakpoints.down("sm")]: {
                    pl: 0,
                    pr: 0
                }
            })
        }>   
        <Box sx={{ maxWidth: 'min(80vw, 80vh)', mt: 1}}>
            {showTitle && <Typography variant="h6" sx={{fontFamily: 'Noto Sans', fontSize: '18px', fontWeight: 700}}>Photos</Typography>}            
            { images.length > 0 && 
                <ImageGallery items={images} additionalClass="gallery" />}
            <HelpIcon sx={{color: '#195E97', fontSize: '14px', fontWeight: 400}}/><Typography variant="caption" sx={{fontFamily: 'Noto Sans', fontSize: '14px', fontWeight: 400}}>{displayText}</Typography>
        </Box>
        <Stack sx={(theme) => ({
                maxWidth: '50%', maxHeight: '50%',
                [theme.breakpoints.down("md")]: {
                    pl: 0,
                    pr: 0,
                    maxWidth: 'none'
                }
            })
        }>
            { webcamEnabled && <Webcam width="100%" audio={false} ref={webcamRef} screenshotFormat="image/png" videoConstraints={{facingMode: ['environment'], width:1280, height:720}} forceScreenshotSourceSize/>}
        </Stack>
        <Box display='flex' flexDirection='row' sx={(theme) => ({
                alignItems: 'center', justifyContent: 'center', mb: 1,
                [theme.breakpoints.down("md")]: {
                    flexDirection: 'column'
                }
            })
        }>
            { webcamEnabled
                ? <>
                    <Button sx={{mr: 1, mt: 1}} onClick={capture} variant="contained" startIcon={<CenterFocusStrongIcon/>} className="buttonClass">Capture</Button>
                    <Button sx={{mr: 1, mt: 1}} onClick={toggleWebcam} variant="contained" color="error" startIcon={<CloseIcon/>} className="buttonClass">Close</Button>
                </>
                : <>
                    <Button sx={{mr: 1}} onClick={toggleWebcam} variant="contained" startIcon={<CameraAltIcon/>} className="buttonClass">Add Picture</Button>
                    <input type="file" accept="image/*" onChange={upload} style={{display: 'none'}} id="upload-button" />
                    <label style={{ width: '100%' }} htmlFor="upload-button" className="buttonLabelClass">
                        <Button variant="contained" sx={{ mr: 1, mt: 1, mb: 1, width: '100%' }} component="span" startIcon={<FileUploadIcon/>} className="buttonClass">Upload Picture</Button>
                    </label>
                </>
            }
        </Box>
    </Stack>

}

export default Gallery