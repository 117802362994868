
import React from 'react';
import { AzureMapsContext, IAzureMapsContextProps } from 'react-azure-maps';
import { Point } from './InventoryMap';
import { IMapPositionContext, MapPositionContext } from '../../App';

export interface MapControllerProps {
    center: Point
}

const MapController = () => {

    const { mapRef } = React.useContext<IAzureMapsContextProps>(AzureMapsContext)
  const { mapCenter } = React.useContext<IMapPositionContext>(MapPositionContext)

    React.useEffect(() => {
        if (mapRef) {
            mapRef.setCamera({ center: [mapCenter?.longitude, mapCenter?.latitude] })
          } else {
              console.warn('Map Reference is not initialized')
          }
    }, [mapRef, mapCenter])

  return (
    <></>
  );
};

export default MapController;
