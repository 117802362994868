import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { FormControl, MenuItem, Select } from '@mui/material';
import DataService from './DataService';
import './FilterMenu.css'

export interface FilterState {
    assetType: string,
    tagType: string,
    crossFunctionalTeamType: string,
    plantType: string
}

interface FilterProps {
    dataService: DataService,
    filterState: FilterState,
    onChange?: (state: FilterState) => void
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other} = props
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    })
  }))

export function FilterMenu ({dataService, filterState, onChange}: FilterProps) {
    const [plants, setPlants] = React.useState<string[]>(['Plant']);
    const [crossFunctionalTeams, setCrossFunctionalTeams] = React.useState<string[]>(['Team']);
    const [assetTypes, setAssetTypes] = React.useState<string[]>(['Asset Type']);


    React.useEffect(() => {
        (async () => {
            let plants = await dataService.loadPlants()
            plants.unshift('Plant')
            setPlants(plants)

            let teams = await dataService.loadCrossFunctionalTeams()
            teams.unshift('Team')
            setCrossFunctionalTeams(teams)

            let assetTypes = await dataService.loadAssetTypes()
            assetTypes.unshift('Asset Type')
            setAssetTypes(assetTypes)
        })();
    }, [])

    return (
        <Card sx={{padding: 0, height: '50px'}}>
                <CardContent sx={{padding: 1, ":last-child":{paddingBottom: 1, height: '100%'}}} className='cardContent'>    
                    <FormControl sx={{marginRight: 1, height: '100%'}} variant="outlined" className='formControl'>
                        <Select
                            sx={{height: '100%'}}
                            labelId="asset-filter-label"
                            id="asset-filter-select"
                            className='MultiSelect'
                            value={filterState.assetType}
                            onChange={e => { onChange?.({...filterState, assetType: e.target.value})}}
                        >
                            {
                               assetTypes.map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)
                            }
                        </Select>
                    </FormControl> 
                    <FormControl sx={{marginRight: 1,  height: '100%'}} variant="outlined" className='formControl'>
                        <Select
                            sx={{height: '100%'}}
                            labelId="plant-filter-label"
                            id="plant-filter-select"
                            className='MultiSelect'
                            value={filterState.plantType}
                            onChange={e => { onChange?.({...filterState, plantType: e.target.value})}}
                        >                            
                            {
                               plants.map(plant => <MenuItem key={plant} value={plant}>{plant}</MenuItem>)
                            }
                        </Select>
                    </FormControl>               
                    <FormControl sx={{marginRight: 1,  height: '100%'}} variant="outlined" className='formControl'>
                        <Select
                            sx={{height: '100%'}}
                            labelId="asset-tag-filter-label"
                            id="asset-tag-filter-select"
                            className='MultiSelect'
                            value={filterState.tagType}
                            onChange={e => { onChange?.({...filterState, tagType: e.target.value})}}
                        >
                            <MenuItem value="Tag">Tag</MenuItem>
                            <MenuItem value="Barcode">Barcode</MenuItem>
                            <MenuItem value="Satellite">Satellite</MenuItem>
                            <MenuItem value="Serial Number">Serial Number</MenuItem>
                        </Select>
                    </FormControl>                    
                    <FormControl sx={{marginRight: 1,  height: '100%'}} variant="outlined" className='formControl'>
                        <Select
                            sx={{height: '100%'}}
                            labelId="cross-functional-team-filter-label"
                            id="cross-functional-team-filter-select"
                            className='MultiSelect'
                            value={filterState.crossFunctionalTeamType}
                            onChange={e => { onChange?.({...filterState, crossFunctionalTeamType: e.target.value})}}
                        >
                            {
                               crossFunctionalTeams.map(team => <MenuItem key={team} value={team}>{team}</MenuItem>)
                            }
                        </Select>
                    </FormControl>                    
                </CardContent>
        </Card>
    )
}

