import { Box, Card, CardActions, CardContent, CardProps, Collapse, IconButton, IconButtonProps, ListItemButton, Typography, styled } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataService, { InventoryItem, NewInventoryItem } from "../../DataService";
import React from "react";
import InventoryItemForm, { InventoryItemFormType } from "../../InventoryItemForm";

import Gallery, { GalleryFormType } from "../../Gallery";
import Attachments from "../../Attachments";
import ChildComponents from "../../ChildComponents";
import './AssetCardSummary.css';
import ItemSummaryForm from "../../ItemSummaryForm";

interface ExpandCardProps extends CardProps {
    expand: boolean;
}

const ExpandCard = styled((props: ExpandCardProps) => {
    const { expand, ...other} = props
    return <Card {...other} />
})(({theme, expand}) => ({
    padding: 3
}))

export interface AssetCardSummaryState {
    expand: boolean,
    id: number
}

export interface AssetCardSummaryState {
    expand: boolean,
    id: number
}

interface AssetCardSummaryProps {
    item: InventoryItem,
    itemExpand: AssetCardSummaryState,
    dataService?: DataService,
    onClick?: (state: AssetCardSummaryState) => void,
    onViewMap?: () => void,
    onDelete?: () => void
}

function AssetCardSummary ({item, dataService, itemExpand, onClick, onDelete, onViewMap}: AssetCardSummaryProps) {
    const [expanded, setExpanded] = React.useState<boolean>(false)
    const [editable, setEditable] = React.useState<boolean>(false)
    const [visitedStatus, setVisitedStatus] = React.useState<boolean>(false)

    const handleExpand = () => {
        setExpanded(!expanded);
        onClick?.({expand: !itemExpand.expand, id: item.id ? item.id : -1})
    }

    const handleFormEdit = () => {
        setEditable(!editable);
    }

    const handleOnCallback = (e: any) => {
        if(e.tagId || e.tagType || e.tagTypeName){
            item.tagId = e.tagId;
            item.tagType = e.tagType;
            item.tagTypeName = e.tagTypeName;
        }
    }

    const onResetVisited = async (item: InventoryItem | undefined) => {
        if(!!item){
            await dataService?.updateVisitedTime(item);
            setVisitedStatus(!visitedStatus);
        }        
    }

    const onVisited = async (selectedAsset: InventoryItem | undefined) =>{
        if(!!selectedAsset){
            await dataService?.updateVisitedTime(selectedAsset);
            setVisitedStatus(!visitedStatus);
        }
    }

    return (
            <Box>
                <ExpandCard expand={expanded}>
                    {expanded ? 
                        <>
                            <ExpandMoreIcon className="roundIcon"
                                onClick={() => handleExpand()}
                            />
                        </> 
                        : <></>}
                        <ItemSummaryForm item={item} editable={editable} dataService={dataService} expand={expanded} onClick={handleExpand} onFormEdit={handleFormEdit} onViewMap={onViewMap} onClose={handleFormEdit}/>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>                        
                        <CardContent sx={{padding: 1, ":last-child":{paddingBottom: 1}}}>
                            {
                                editable ? 
                                    <InventoryItemForm dataService={dataService} item={item} onChangeCallback={e => handleOnCallback(e)} onViewMap={onViewMap} onDelete={onDelete} formType={InventoryItemFormType.Update} onFormEdit={handleFormEdit} onResetVisited={ asset => {onResetVisited(asset)}}/>
                                : <></>
                            }
                            {(!!item && !!item.assetType && item.assetType !== "HEI") &&
                                <Gallery formType={GalleryFormType.Show} dataService={dataService} item={item} />
                            }
                            <Attachments dataService={dataService} item={item} />
                            <ChildComponents dataService={dataService} item={item} visitedStatus={visitedStatus} onVisitedTime={ asset => { onVisited(asset)}} onResetVisitedTime={ asset => {onResetVisited(asset)}}/>
                        </CardContent>
                    </Collapse>
                </ExpandCard>
            </Box>)
}

export default AssetCardSummary