import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './SearchMenu.css'
import MyLocationIcon from '@mui/icons-material/MyLocation';

export interface SearchState {
    searchedText: string
}

export enum SearchMenuFormType {
    AssetMap,
    AssetTable,
}

interface SearchProps {
    searchState: SearchState,
    formType: SearchMenuFormType,
    onKeyDown?: (state: SearchState) => void
    onClick?: (state: SearchState) => void
    onCenterMap?:() => void
}

export function SearchMenu ({searchState,formType, onKeyDown, onClick, onCenterMap}: SearchProps) {
    const [searchStr, setSearchStr] = React.useState<string>(searchState.searchedText || '');
    const [showMapIcon, setShowMapIcon] = React.useState(formType == SearchMenuFormType.AssetMap);

    const onChange = (e: any) =>{
        setSearchStr(e.target.value);
    }

    const resetSearchText = (e:any) =>{
        searchState.searchedText = "";
        setSearchStr("");
    }

    return (
        <Card sx={{padding: 0}}>
                <CardContent sx={{padding: 1, ":last-child":{paddingBottom: 1}}}>
                    <FormControl variant='standard' sx={{display: 'flex', flexDirection: 'row'}}>
                        <TextField  
                            sx={{flexGrow: 1}}                          
                            id="searched-text-filter-textfield"
                            label="Search"
                            variant='outlined'
                            value={searchStr}
                            onChange={e => { onChange(e) }}
                            onKeyDown={e => e.key === "Enter" || e.key === "Return" ? onKeyDown?.({...searchState, searchedText: searchStr}) : searchState}
                            InputProps={
                                {endAdornment:
                                    (
                                        <InputAdornment position='end'>
                                            {
                                                searchStr.length === 0 ? <SearchIcon/> : <CloseIcon className='closeIcon' onClick={e => {resetSearchText(e); onClick?.({...searchState, searchedText: ""})}}/>
                                            }
                                        </InputAdornment>
                                    )
                                }}
                        />
                        {showMapIcon ? 
                            <MyLocationIcon sx={{width: '4rem', height: '2rem', alignSelf: 'center', pl: 1, color: '#195E97', ":hover": {cursor: 'pointer'}}} onClick={e => onCenterMap?.()}/>
                            : <></>
                        }                        
                    </FormControl>
                </CardContent>
        </Card>
    )
}

