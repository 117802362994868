import EditRoundedIcon from '@mui/icons-material/EditRounded';
import TurnRightRoundedIcon from '@mui/icons-material/TurnRightRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './AssetCardSummary.css';
import { InventoryFormActionProps } from "../../InventoryFormActions"
import React from 'react';
import { Grid } from '@mui/material';

export interface AssetCardControlIconFormProps extends InventoryFormActionProps {
    editable: boolean,
    onFormEdit?: () => void,
    onViewMap?: () => void,
    onClose?: () => void
}

function AssetCardControlIconForm({editable, onFormEdit, onViewMap, onClose}: AssetCardControlIconFormProps) {

    return <Grid item flexGrow={0}>
                <Grid container direction='row'>
                    <Grid>
                        { editable? 
                            <CloseRoundedIcon className="roundIcon"
                                onClick={onClose}
                            />
                            :
                            <EditRoundedIcon className="roundIcon"
                                onClick={onFormEdit}
                        />}
                        <TurnRightRoundedIcon className="roundIcon"
                            onClick={onViewMap}
                        /> 
                    </Grid>
                </Grid>
            </Grid>;
}

export default AssetCardControlIconForm;