import { CardActions, Grid, Typography } from "@mui/material"
import { InventoryItem } from "./DataService"
import { InventoryFormActionProps } from "./InventoryFormActions"
import React from 'react';
import InventoryItemSummaryForm from "./InventoryItemSummaryForm";
import InventoryItemImageForm from "./InventoryItemImageForm";
import DataService from "./DataService";
import AssetCardControlIconForm from "./tabs/inventory/AssetCardControlIconsForm";

export interface ItemSummaryFormProps extends InventoryFormActionProps {
    item: Partial<InventoryItem>,
    editable: boolean,
    dataService?: DataService,
    expand: boolean,
    onClick?: () => void,
    onFormEdit?: () => void,
    onViewMap?: () => void,
    onClose?: () => void
}

function ItemSummaryForm({item, editable, dataService, expand, onClick, onFormEdit, onViewMap, onClose}: ItemSummaryFormProps) {

    return <CardActions sx={{borderBottom: 1, borderBlockColor: '#DADCE0'}} disableSpacing className="itemSummaryFormCard">
        <InventoryItemSummaryForm item={item} expand={expand} onClick={onClick}/>
        {
            expand ? 
                <AssetCardControlIconForm editable={editable} onFormEdit={onFormEdit} onViewMap={onViewMap} onClose={onClose}/>                     
            :
                <InventoryItemImageForm dataService={dataService} item={item}/>
        }                    
    </CardActions>;
}

export default ItemSummaryForm;