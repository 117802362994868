import { Grid, Typography } from "@mui/material"
import { InventoryItem } from "./DataService"
import { InventoryFormActionProps } from "./InventoryFormActions"
import React from 'react';

export interface InventoryItemSummaryFormProps extends InventoryFormActionProps {
    item: Partial<InventoryItem>,
    expand: boolean,
    onClick?: () => void
}

function InventoryItemSummaryForm({item, expand, onClick}: InventoryItemSummaryFormProps) {

    return <Grid container direction='row' onClick={onClick}>
                <Grid container direction='row'>
                    <Typography variant="h6" className="itemText">{item.name || ''}</Typography>
                    <Typography variant="h6" className="itemText">&nbsp;&#8226; ID #</Typography>
                    <Typography variant="h6" className="itemText">{item.id || ''}</Typography>
                </Grid>
                <Grid container direction='row'>
                    <Typography variant="body1" className="itemText itemTextBody">{item.assetType || ''}&nbsp;&#8226;&nbsp;</Typography>
                    <Typography variant="body1" className="itemText itemTextBody">{item.plant || ''}&nbsp;&#8226;&nbsp;</Typography>
                    <Typography variant="body1" className="itemText itemTextBody">{item.crossFunctionalTeam || ''}</Typography>
                </Grid>
                {item.tagTypeName ?
                    <Grid container direction='row'>
                        <Typography variant="body1" className="itemText itemTextBody">{item.tagTypeName || ''}&#58;&nbsp;</Typography>
                        <Typography variant="body1" className="itemText itemTextBody itemTag">{item.tagId || ''}</Typography> 
                    </Grid>
                    : <></>
                } 
                {
                    item.description ?
                    <>
                        <Grid container direction='row' sx={{paddingBottom: 2}}></Grid>
                        <Grid container direction='row' sx={{height: !expand ? '22px' : 'none'}}>
                            <Typography sx={{height: '100%', overflow: 'hidden'}} variant="caption" className="itemText itemTextCaption">{item.description || ''}</Typography>
                        </Grid>
                    </>
                    : <></>
                }                
            </Grid>;
}

export default InventoryItemSummaryForm;