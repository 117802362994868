import { Card, CardMedia, Grid, Typography } from "@mui/material"
import { DataService,  InventoryItem } from "./DataService"
import { InventoryFormActionProps } from "./InventoryFormActions"
import React from 'react';
import defaultImg from './img/default.png';

export interface InventoryItemImageFormProps extends InventoryFormActionProps {
    dataService?: DataService,
    item: Partial<InventoryItem>
}

function InventoryItemImageForm({dataService, item}: InventoryItemImageFormProps) {

    const [thumbnail, setThumbnail] = React.useState<any>()
    const SMARTS_API_BASE_URL = process.env.REACT_APP_SMARTS_API_BASE_URL;

    React.useEffect(() => {
        if(item && item.imageId){
            setThumbnail(`${SMARTS_API_BASE_URL}/api/images/gallery/asset/thumbnail?id=${item.imageId}`)
        }
        else if(item && item.imageId === null){
            setThumbnail(defaultImg);
        }
    }, [item, dataService]);


    return <Grid item flexGrow={0}>
                <Grid container direction='row'>
                    <Grid flexGrow={0}>
                        <Card>
                            <CardMedia sx={{width: '84px', height: '84px'}}
                                component="img"
                                height="100"
                                image={thumbnail}
                            />
                        </Card>
                    </Grid>
                </Grid>            
            </Grid>
}

export default InventoryItemImageForm;